// TODO should be renamed to MerchantUserSelector
import {registerStyle, styleRule, StyleSheet, styleRuleInherit, UI} from "stem-core/src/ui/UI";
import {TextInput} from "stem-core/src/ui/input/Input";
import {EndpointPaginator} from "client/state/EndpointPaginator";
import {Modal, ModalStyle} from "blinkpay/ui/Modal";
import {MakeLinkingRowClass, SimpleTable} from "./SimpleTable";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {Button} from "stem-core/src/ui/UI";
import {MerchantAddUserModal} from "../common/modals/MerchantAddUserModal";


class SelectUserModalStyle extends ModalStyle {
    @styleRule
    userSearchInputStyle = {
        marginTop: 20,
        marginBottom: 20,
        fontWeight: "normal",
    };

    @styleRuleInherit
    modal = {
        maxWidth: "initial",
        minWidth: 700,
        minHeight: 600,
        width: "auto",
    };

    @styleRuleInherit
    title = {
        flex: "initial",
    };

    @styleRule
    emailColumnText = {
        cursor: "pointer"
    }

    @styleRule
    createUserButton = {
        width: "fit-content",
        marginTop: 20,
    }
}

@registerStyle(SelectUserModalStyle)
export class SelectUserModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            paginatorStore: null,
            paginatorEndpoint: null,
            title: "Select User",
            description: null,
            columns: null,
            filters: {},
            allowUserCreation: false,
            createUserBtnLabel: "Add user",
            onSelect: NOOP_FUNCTION,
            createUserModalClass: MerchantAddUserModal,
            createUserModalOptions: {}
        }
    }

    paginator = new EndpointPaginator(this.options.paginatorStore, this.options.paginatorEndpoint, {
        order: "-createdAt",
        ...this.options.filters,
    });

    updateFilter() {
        this.paginator.updateFilter({
            nameOrEmailSearch: this.userSearchInput.getValue() || null,
        });
    }

    handleUserEmailSelection(user) {
        this.options.onSelect(user);
        this.hide();
    }

    render() {
        const {description, allowUserCreation, createUserBtnLabel, createUserModalClass, createUserModalOptions} = this.options;
        const columns = this.options.columns || [
            ["Email", user => user.getEmail()],
            ["Name", user => user.getName()],
            ["Type", user => user.userType],
            ["Date Joined", user => user.createdAt],
        ];

        const TableRowClass = MakeLinkingRowClass((user) => this.handleUserEmailSelection(user));

        return [
            description ? <div>{description}</div> : null,
            <TextInput ref="userSearchInput"
                       className={this.styleSheet.userSearchInputStyle}
                       placeholder="Search by email or name"
                       onChange={() => this.updateFilter()}/>,
            <SimpleTable paginator={this.paginator} columns={columns} rowClass={TableRowClass} />,
            allowUserCreation ? <Button className={this.styleSheet.createUserButton}
                                             onClick={() => createUserModalClass.show({
                                                 title: createUserBtnLabel,
                                                 ...(createUserModalOptions || {}),
                                                 onSuccess: (user) => this.handleUserEmailSelection(user),
                                             })}>
                {createUserBtnLabel}
            </Button> : null
        ];
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(this.options.paginatorStore, () => this.redraw());
    }
}


class UserSelectorStyle extends StyleSheet {
    @styleRule
    userSelectorContainer = {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        width: "fit-content",
        margin: "20px 20px 0px 0px",

    };

    @styleRule
    addUserButton = {
        fontWeight: "normal",
        marginRight: 20,
    };
}

@registerStyle(UserSelectorStyle)
export class UserSelector extends UI.Element {
    selected = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            btnText: "Choose user",
            displayLabelAfterSelect: true,
            notSelectedLabel: "No user selected.",
            modalOptions: {},
            onSelect: NOOP_FUNCTION,
        }
    }

    getDefaultModalOptions() {
        return {
            title: this.options.btnText || "Select user",
            description: null,
            filters: {},
            allowUserCreation: false,
            onSelect: (user) => this.handleUserSelected(user),
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.userSelectorContainer);
    }

    getSelected() {
        return this.selected;
    }

    resetSelected() {
        this.selected = null;
        this.redraw();
    }

    handleUserSelected(user) {
        this.selected = user;
        this.redraw();
        this.options.onSelect(user);
    }

    render() {
        const {btnText, modalOptions, notSelectedLabel, displayLabelAfterSelect} = this.options;

        return [
            <Button className={this.styleSheet.addUserButton}
                         onClick={event => {
                             event.preventDefault();
                             SelectUserModal.show({...this.getDefaultModalOptions(), ...modalOptions,})
                         }}>
                {btnText}
            </Button>,
            displayLabelAfterSelect && (this.selected ? <div>{this.selected.email}</div> : notSelectedLabel),
        ]
    }
}
