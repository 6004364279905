import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Panel} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {TopLevelTabArea} from "../../../dashboard/common/theme/TabAreaStyle.js";
import {StepIndicator} from "../../../blinkpay/ui/StepIndicator.jsx";

// TODO Look into something like Storybook
export class TempTestingPage extends UI.Element {
    render() {
        return [
            <h1>General testing page</h1>,
            <TopLevelTabArea>
                <Panel title={"Step Indicator"}>
                    <StepIndicator />
                </Panel>
            </TopLevelTabArea>,
        ]
    }
}
