import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {UserSessionsList} from "./user-sessions/UserSessionsList.jsx"
import {UserPaymentMethodList} from "./payment-methods/PaymentMethodList.jsx";
import {UserChargesList} from "./payments/UserChargesList.jsx";
import {UserPaymentList} from "./payments/PaymentList.jsx";
import {UserSubscriptionList} from "./subscriptions/SubscriptionList.jsx";
import {UserPreferences} from "./preferences/PreferencesDetail.jsx";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {UserWallet} from "./wallet/UserWallet.jsx";
import {UserProfile} from "./profile/UserProfile.jsx";
import {apiAdminFetchUser} from "../../../client/state/AdminUserStore.js";


export class AdminUserPanel extends UI.Element {
    async fetchUser() {
        const userId = this.options.args[0]; // TODO: our route passing suck just differently than the react one, simplify
        const user = await apiAdminFetchUser(userId);
        this.updateOptions({user});
    }

    render() {
        const {user} = this.options;
        if (!user) {
            this.fetchUser();
            return <LoadingSpinner/>;
        }

        return <div>
            <UserProfile user={user}/>
            <UserPreferences user={user}/>
            <UserWallet user={user}/>
            <UserPaymentMethodList user={user}/>
            <UserPaymentList user={user}/>
            <UserChargesList user={user}/>
            <UserSubscriptionList user={user}/>
            <UserSessionsList user={user}/>
        </div>
    }
}
