import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {Money} from "../../../../stem-core/src/localization/Money.js";
import {apiAdminMarkChargeFailed, TopUpPaymentStore, TopUpStatus} from "../../../../client/state/TopUpPaymentStore.js";
import {EndpointPaginator} from "../../../../client/state/EndpointPaginator.js";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable.jsx";
import {Card} from "../../ui/Card.jsx";
import {Button} from "../../../../stem-core/src/ui/button/Button.jsx";
import {RevertTopUpModal} from "./RevertTopUpModal.jsx";
import {FormatPaymentMethodDescription} from "../../../../blinkpay/common/PaymentMethodDescription.jsx";
import {ConfirmationModal} from "../../../../blinkpay/ui/ConfirmationModal.jsx";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives.jsx";
import {RadioButtonsInput} from "../../../../core/ui/input/radio/RadioButtonsInput.jsx";
import {BlinkInputField} from "../../../../dashboard/common/Input.jsx";

async function ConfirmChargeMarkFailed(charge) {
    const confirm = await ConfirmationModal.prompt({
        title: "Confirm Failed",
        confirmLabel: "Manually set as failed",
    });

    if (confirm) {
        await apiAdminMarkChargeFailed({chargeId: charge.id});
    }
}

@autoredraw(TopUpPaymentStore)
export class UserChargesList extends UI.Element {
    canOverwrite() {
        return false; // No reusage
    }

    render() {
        const {user} = this.options;
        let apiFilters = {};
        if (user) {
            apiFilters.userIds = [user.id]
        }
        this.paginator = this.paginator || new EndpointPaginator(TopUpPaymentStore, "/admin/top_up_payments/list", apiFilters);

        const columns = [
            ["Id", item => item.id],
            (user == null) && ["User", item => <Link href={`/users/${item.user.id}`}>{item.user.getEmail()}</Link>],
            ["PP Charge Id", item => item.paymentProcessorChargeId],
            ["Payment Method", item => FormatPaymentMethodDescription(item.getPaymentMethod())],
            ["Payment processor", item => item.paymentMethod?.paymentProcessor],
            ["Amount", item => Money.format(item)],
            ["Status", item => item.status],
            ["Scope", item => item.resourceType ? `${item.resourceType} (${item.resourceId})` : item.isAutomatic ? "Auto top up" : "Manual top up"],
            ["Date", item => item.createdAt],
            ["Source", item => item.source],
            ["Action", item => {
                return [
                    (item.status === TopUpStatus.NOT_PROCESSED) && <Button onClick={() => ConfirmChargeMarkFailed(item)}>Mark Failed</Button>,
                    <Button onClick={() => RevertTopUpModal.show({user, charge: item})}>Revert</Button>
                ];
            }],
        ].filter(x => x); // Cleanup the falses

        return (
            <Card title="Charges">
                <BlinkInputField label="Status">
                    <RadioButtonsInput
                        values={[...TopUpStatus.all(), "Any"]}
                        initialValue="Any"
                        onChange={(value) => {
                            if (value === "Any") {
                                value = null;
                            }
                            this.paginator.updateFilter({status: value});
                        }}
                        ref="subscriptionTypeFilter"
                    />
                </BlinkInputField>
                <SimpleTable columns={columns} paginator={this.paginator}/>
            </Card>
        );

    }
}
