import {UI} from "../../../../../../stem-core/src/ui/UIBase";
import {DateCalendarMonthInputInterface} from "../../base/DateCalendarMonthInputInterface";
import {DateRangeCalendarDay} from "./DateRangeCalendarDay";
import {CURRENT_PICK} from "../../DateRangePickerConstants";


export class DateRangeCalendarMonthInput extends DateCalendarMonthInputInterface {
    setOptions(options) {
        super.setOptions(options);
    }

    renderDayElement(date) {
        const {monthDate, minDate, maxDate} = this.options;
        const {startDate, endDate, currentPick} = this.value;

        return <DateRangeCalendarDay date={date} hoverDate={this.hoverDate} onHover={date => this.onDateHover(date)}
                                     onSelect={date => this.onDateSelection(date)}
                                     {...{startDate, endDate, currentPick, monthDate, minDate, maxDate}}/>
    }

    onDateSelection(date) {
        let {startDate, endDate, currentPick} = this.value;

        if (currentPick === CURRENT_PICK.startDate) {
            startDate = date.getStartOfDay();
            if (!endDate.isAfter(startDate)) {
                endDate = startDate.getEndOfDay();
            }
            currentPick = CURRENT_PICK.endDate;
        } else {
            endDate = date.getEndOfDay();
            if (!startDate.isBefore(endDate)) {
                startDate = endDate.getStartOfDay();
            }
            currentPick = CURRENT_PICK.startDate;
        }
        this.setAndDispatchValue({startDate, endDate, currentPick});
    }
}
