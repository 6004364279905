import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {SortableTableInterface} from "../../../stem-core/src/ui/table/SortableTable.jsx";
import {Table} from "../../../stem-core/src/ui/table/Table.jsx";
import {StemDate} from "../../../stem-core/src/time/Date.js";
import {Link} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {LegalEntityModal} from "./legal-entities/LegalEntityModal.jsx";


// TODO: Fix sort icons
export class MerchantTableList extends SortableTableInterface(Table) {
    getDefaultOptions(options) {
        const column = (name, projection, rawProjection) => ({
            value: projection,
            rawValue: rawProjection || projection,
            headerName: name,
            headerStyle: {
                textAlign: "center",
                margin: "auto",
                verticalAlign: "middle",
            },
        });

        const moneyColumn = (name, projection) => ({
            ...column(name, (merchant) => projection(merchant) || "-"),
            rawValue: (merchant) => {
                const value = projection(merchant);
                console.log("value", value);
                return value?.getAmount() || 0;
            },
            cellStyle: {
                textAlign: "right",
                width: "10%",
                verticalAlign: "middle",
                fontWeight: "bold",
            }
        });

        return {
            ...super.getDefaultOptions(options),
            columns: [
                column("Name",
                        merchant => <Link href={`/merchants/${merchant.id}`}>
                            {merchant.name ?? "??"}
                    </Link>,
                        merchant => (merchant.name ?? "??")),
                column("Legal entity", merchant => {
                    if (!merchant.legalEntity) {
                        return "??"
                    }

                    //TODO: add modal here
                    return <div onClick={() => LegalEntityModal.show({legalEntity: merchant.legalEntity})}
                                style={{cursor: "pointer"}}>
                        {merchant.legalEntity.name}
                    </div>
                }),
                column("Date added", merchant => StemDate.format(merchant.createdAt, "YYYY/MM/DD")),
                moneyColumn("All time revenue", merchant => merchant.getRevenue()?.allTimeRevenue),
                moneyColumn("Last 30d revenue", merchant => merchant.getRevenue()?.last30dRevenue),
                moneyColumn("Last 24h revenue", merchant => merchant.getRevenue()?.last24hRevenue),
                // TODO: Add this later
                // moneyColumn("Monthly recurring", merchant => merchant.monthlyRecurring ?? 0),
                moneyColumn("Outstanding balance", merchant => merchant.getRevenue()?.outstandingBalance),
            ],
        };
    }
}
