import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {Theme} from "../../../stem-core/src/ui/style/Theme";
import {FlatTabArea} from "../../../stem-core/src/ui/tabs/FlatTabArea";
import {Table} from "../../../stem-core/src/ui/table/Table";
import {ButtonStyle} from "../../../stem-core/src/ui/button/ButtonStyle";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {SetDefaultDateFormat} from "../../../stem-core/src/time/Date";
import {CollapsiblePanelStyle} from "../../../stem-core/src/ui/collapsible/Style";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {enhance} from "../../../stem-core/src/ui/Color";
import {StyleSheet} from "../../../stem-core/src/ui/Style";
import {Money} from "../../../stem-core/src/localization/Money.js";
import {BlinkTabAreaStyle} from "./TabAreaStyle";
import {BlinkTableStyle} from "./TableStyle";
import {GlobalStyleSheet} from "../../GlobalStyleSheet";
import {initializeTheme} from "../../../blinkpay/theme/StyleConstants.js";
import {CodeEditor} from "../../../stem-core/src/ui/CodeEditor";
import {ensure} from "../../../stem-core/src/base/Require";
import {DashboardDefaultDateFormatter} from "../../misc/DashboardSettings";
import {ProgressBar} from "../../../stem-core/src/ui/ProgressBar.jsx";
import {DashboardProgressBarStyle} from "./ProgressBarStyle.js";

export class DashboardPageStyle extends StyleSheet {
    @styleRule
    mainPage = {
        boxSizing: "border-box", // TODO: should be default for everything
        width: () => `calc(100% - ${Theme.props.SIDEBAR_WIDTH}px)`,
        background: this.themeProps.COLOR_BACKGROUND,
        position: "relative",
        float: "right",
        minHeight: "100vh", // Leaving it to height will mess up the scroll
        padding: 36,
        paddingTop: 0,
        overflow: "overlay", // So that the scroll bar doesn't shift the page when appearing
    };

    @styleRule
    userEmail = {
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 240,
        fontWeight: "bold",
        cursor: "pointer",
        color: this.themeProps.LINK_COLOR,
    }
}

class BlinkCollapsiblePanelStyle extends CollapsiblePanelStyle {
    @styleRuleInherit
    panel = {
        borderColor: this.themeProps.CARD_BORDER_COLOR,
        borderRadius: this.themeProps.CARD_BORDER_RADIUS,
    }

    @styleRuleInherit
    heading = {
        fontSize: 12,
        fontWeight: 700,
        backgroundColor: this.themeProps.CARD_HEADER_BACKGROUND_COLOR,
        textTransform: "uppercase",
        borderTopLeftRadius: this.themeProps.CARD_BORDER_RADIUS,
        borderTopRightRadius: this.themeProps.CARD_BORDER_RADIUS,
        ":hover": {
            backgroundColor: enhance(this.themeProps.CARD_HEADER_BACKGROUND_COLOR, 0.01),
        }
    }
}


class BlinkButtonStyle extends ButtonStyle {
    @styleRuleInherit
    container = {
        transition: this.themeProps.DEFAULT_TRANSITION,
        margin: "2px 8px",
        cursor: "pointer",
    }
}

// TODO @branch this should be in the App
CodeEditor.requireAce = async (callback) => {
    await ensure("/resources/js/ace/ace.js");
    if (!CodeEditor.AceRange) {
        CodeEditor.langToolsSrc = "/resources/js/ace/ext-language_tools.js";
        CodeEditor.AceRange = window.ace.require("ace/range").Range;
    }
    if (callback) {
        callback();
    }
};


export function registerDashboardStyleSheets() {
    initializeTheme();

    // TODO: cleanup some of these
    Theme.setProperties({
        PAGE_TEXT_COLOR: "#232323",
        INACTIVE_BORDER_COLOR: "#E5EAE9",
        ACTIVE_BORDER_COLOR: "#BBD4D8",

        SIDEBAR_WIDTH: 300,
        SIDEBAR_LINK_DEFAULT_COLOR: "#646464",
        SIDEBAR_LINK_ACTIVE_COLOR: props => props.BUTTON_COLOR, // TODO PRIMARY, deprecate usages
        SIDEBAR_BACKGROUND: "#F7FAFC",

        BUTTON_BASE_STYLE: props => ({
            fontSize: props.FONT_SIZE_NORMAL,
            padding: "6px 12px",
        }),

        BUTTON_PRIMARY_COLOR: props => props.WHITE,
        BUTTON_PRIMARY_BACKGROUND: props => props.WALLET_11,
        BUTTON_PRIMARY_BORDER_COLOR: "transparent",
        BUTTON_PRIMARY_TEXT_SHADOW: "0 1px 2px rgba(6,73,83,.12)",
        BUTTON_PRIMARY_SHADOW: "0 2px 6px 0 rgba(6, 73, 83, 0.24)",
        BUTTON_PRIMARY_HOVER_BACKGROUND: props => props.WALLET_13,
        BUTTON_PRIMARY_HOVER_BORDER_COLOR: null,
        BUTTON_PRIMARY_HOVER_SHADOW: "0 2px 10px 0 rgba(6,73,83,.48)",

        BUTTON_SECONDARY_COLOR: props => props.TEXT_PRIMARY_COLOR,
        BUTTON_SECONDARY_BACKGROUND: props => props.WHITE,
        BUTTON_SECONDARY_BORDER_WIDTH: 1,
        BUTTON_SECONDARY_BORDER_COLOR: props => props.TEXT_PRIMARY_COLOR,
        BUTTON_SECONDARY_SHADOW: "0 2px 8px rgba(6,73,83,.12)",
        BUTTON_SECONDARY_HOVER_SHADOW: props => "0 2px 8px rgba(6,73,83,.24), inset 0 0 0 1px " + props.WALLET_11,

        BASE_CARD_WIDTH: 480,
        BASE_CARD_MAX_WIDTH: "min(90vw, 900px)",

        TEXT_PRIMARY_COLOR: "#1A2F36",
        TEXT_SECONDARY_COLOR: "#75797F",

        DIVIDER_LINE_COLOR: "#DDDDDD",
        DIVIDER_TEXT_COLOR: "#3F3F3F",

        CHART_TOOLTIP_BACKGROUND_COLOR: "#fff",
        CHART_TOOLTIP_DROPDOWN_SHADOW: "0 2px 8px 5px rgba(0,0,0,.1), 0 5px 27px -5px rgba(0,0,0,.37)",
        CHART_LINE_COLOR: "#F2F2F2",
        CHART_BAR_BACKGROUND_COLOR_1: "#DEA800",
        CHART_BAR_BACKGROUND_COLOR_2: "#4C837A",
        CHART_BAR_BACKGROUND_COLOR_3: "#0A2B32",

        APP_BACKGROUND: "#f7f7f7",

        DATE_PICKER_DAY_LIGHT_GREY: "#E5E5E5",
        DATE_PICKER_DAY_DARK_GREY: "#D4D4D4",
        DATE_PICKER_DAY_LIGHT_GREEN: "rgb(152 187 193)",
        DATE_PICKER_DAY_MEDIUM_GREEN: "#1E727E",
        DATE_PICKER_DAY_DARK_GREEN: "#086472", // TODO: yeah, great, datepicker specific coloses
        DATE_PICKER_INPUT_ACTIVE_COLOR: Theme.props.WALLET_6,
        DATE_PICKER_INPUT_ACTIVE_BOX_SHADOW: "0 8px 16px -11px rgba(0,0,0,.8)",
        DATE_PICKER_INPUT_INACTIVE_BORDER_COLOR: "#F0F0F0",
        DATE_PICKER_INPUT_LABEL_COLOR: Theme.props.MERCHANT_3,
        DATE_PICKER_INPUT_PICK_A_DATE_COLOR: Theme.props.MERCHANT_2,
        DATE_PICKER_INPUT_COLOR: Theme.props.MERCHANT_3,

        COLOR_SECONDARY: "#FFF",

        LINK_COLOR: "#337AB7",
        CARD_BORDER_RADIUS: 4,
        CARD_HEADER_BACKGROUND_COLOR: "#EBF3F3",
        CARD_HEADER_HEIGHT: 40,
        CARD_BORDER_COLOR: "#E5E5E5",

        BUTTON_BORDER_RADIUS: 6,
        BUTTON_COLOR: "#005A64", // TODO @theme deprecate

        INPUT_DEFAULT_HEIGHT: 36, // Aiming to have all the filterable input of this height (without label)

        INPUT_BORDER_COLOR: "#E5EAE9",
        INPUT_BORDER_RADIUS: props => props.BUTTON_BORDER_RADIUS,

        INPUT_BACKGROUND: "#FFFFFF",
        INPUT_LABEL_COLOR: "#3F3F3F",
        INPUT_TEXT_COLOR: "#232323",

        TAB_AREA_BORDER_RADIUS: props => props.CARD_BORDER_RADIUS,

        FLAT_TAB_AREA_UNDERLINE_HEIGHT: 2,
        FLAT_TAB_AREA_TAB_STYLE: props => ({
            backgroundColor: props.CARD_HEADER_BACKGROUND_COLOR,
            fontSize: 12,
            fontWeight: "bold",
            textTransform: "uppercase",

        }),

        RADIO_BUTTON_PADDING: "8px 16px",
        RADIO_BUTTON_FLEX: "",

        CHECKBOX_BORDER_COLOR: Theme.props.WALLET_11,
        CHECKBOX_ENABLED_BACKGROUND_COLOR: Theme.props.WALLET_11,
        CHECKBOX_CHECKMARK_COLOR: Theme.props.WHITE,
        CHECKBOX_SIZE: "1em"
    });

    GlobalStyleSheet.initialize();

    Theme.register(FlatTabArea, BlinkTabAreaStyle);
    Theme.register(Table, BlinkTableStyle);
    Theme.register(Button, BlinkButtonStyle);
    Theme.register(CollapsiblePanel, BlinkCollapsiblePanelStyle);
    Theme.register(ProgressBar, DashboardProgressBarStyle);

    SetDefaultDateFormat(DashboardDefaultDateFormatter);

    Money.useFormatter = true;
}

window.Theme = Theme;
