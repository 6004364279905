// TODO The admin app needs some cleanups
import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Route, Router, TerminalRoute} from "../../../stem-core/src/ui/Router.jsx";
import {UsersPage} from "../users/UsersPage.jsx";
import {DashboardSidePanel} from "../../../dashboard/DashboardSidePanel.jsx";
import {AdminMerchantsPage} from "../merchants/AdminMerchantsPage.jsx";
import {APIEndpointsSummary} from "../autogenerated-docs/APIEndpointsSummary.jsx";
import {LatestPaymentsTable} from "../autogenerated-docs/LatestPaymentsTable.jsx";
import {EmailTemplatesPage} from "../emailTemplates/EmailTemplatesPage.jsx";
import {AdminUserPanel} from "../users/UserPage.jsx";
import {AdminLoginPage} from "./LoginPage.jsx";
import {apiClient} from "../../../client/connection/BlinkApiClient.js";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {MerchantPage} from "../merchants/MerchantPage.jsx";
import {Theme} from "../../../stem-core/src/ui/style/Theme.js";
import {PayoutsPage} from "../payouts/PayoutsPage.jsx";
import {FileSaver} from "../../../stem-core/src/base/FileSaver.js";
import {registerDashboardStyleSheets, DashboardPageStyle} from "../../../dashboard/common/theme/DashboardTheme.js";
import {Page} from "../../../dashboard/PageHandler.js";
import {BlinkLogo} from "../../../core/ui/SVGElements.jsx";
import {AdminHomePage} from "./AdminHomePage.jsx";
import {CDSRequestsPage} from "../merchants/CDSRequestsPage.js";
import {CompliancePage} from "./CompliancePage.js";
import {AnalyticsEventsPage} from "../analytics/AnalyticsEventsPage.jsx";
import {BlinkLogMessagesPage} from "../analytics/LogMessagesPage.jsx";
import {authService} from "../../../client/connection/services/AuthService.js";
import {iFrameConnection} from "../../../blinkpay/services/IFrameConnection.js";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages.js";
import {LoginRedirectPage} from "../../../core/external-auth/LoginRedirectPage.jsx";
import {AdminChargesPage} from "../../pages/AdminChargesPage.jsx";
import {AnalyticsIcon, EmailIcon, MerchantIcon, ReportsIcon, RevenueIcon, UsersIcon} from "../../../dashboard/SVGElements.jsx";
import {TempTestingPage} from "./TempTestingPage.js";
import {GlobalState} from "../../../stem-core/src/state/State.js";
import {LanguageStore} from "../../../client/state/LanguageStore.js";
import {englishTranslationMap} from "../../../blinkpay/Messages.js";
import {setLanguageStore} from "../../../stem-core/src/ui/Translation.js";
import {MerchantConfigBlobPage} from "../../../dashboard/pages/MerchantConfigBlobPage.jsx";


const AdminPages = {
    MERCHANTS: new Page("merchants", "Merchants", AdminMerchantsPage, {icon: MerchantIcon}),
    USERS: new Page("users", "Users", UsersPage, {icon: UsersIcon}),
    ANALYTICS_EVENTS: new Page("analytics-events", "Analytics Events", AnalyticsEventsPage, {icon: AnalyticsIcon}),
    LOG_MESSAGES: new Page("log-messages", "Log Messages", BlinkLogMessagesPage, {icon: ReportsIcon}),
    EMAIL_TEMPLATES: new Page("email-templates", "Email Templates", EmailTemplatesPage, {icon: EmailIcon}),
    LATEST_PAYMENTS: new Page("latest-payments", "Latest Payments", LatestPaymentsTable, {icon: RevenueIcon}),
    LATEST_CHARGES: new Page("latest-charges", "Latest Charges", AdminChargesPage),
    API_DETAILS: new Page("api-details", "API Details", APIEndpointsSummary),
    PAYOUTS: new Page("payouts", "Payouts", PayoutsPage),
    CDS_REQUESTS: new Page("cds-requests", "CDS Requests", CDSRequestsPage),
    COMPLIANCE: new Page("compliance", "Compliance", CompliancePage),
    TESTING: new Page("testing", "General testing", TempTestingPage),
    CONFIG_BLOB: new Page("config-blob", "Config Blob", MerchantConfigBlobPage),
}

const UNAUTH_ROUTES = new Route(null, AdminLoginPage, [
    new TerminalRoute(["login-redirect"], LoginRedirectPage),
    new TerminalRoute(["%s"], AdminLoginPage),
])


const ROUTES = new Route(null, AdminHomePage, [
    AdminPages.MERCHANTS.toRoute([
        new Route("%s", MerchantPage, "Merchant"),
    ]),
    AdminPages.USERS.toRoute([
        new TerminalRoute("%s", AdminUserPanel),
    ]),
    ...Array.from(Object.values(AdminPages)).map(page => page.toRoute()),
]);

LanguageStore.importState([
    {
        id: 1,
        translationMap: englishTranslationMap,
    },
]);

setLanguageStore(LanguageStore);


// TODO @cleanup Inherit the base BlinkApp
export class AdminApp extends UI.Element {
    haveLoaded = false;

    static init() {
        self.GlobalState = GlobalState; // expose it for debugging
        this.initializeApiConnection();
        this.initializeTheme();

        Page.titleSuffix = " - Blink Admin";
        this.create(document.body);

        // TODO @branch @auth use this everywhere in the same way
        iFrameConnection.addListener(IFrameMessages.USER_TOKEN_UPDATE, ({token}) => {
            const {t, e} = JSON.parse(token);
            authService.token.setToken(t, e);
            window.location.href = "/"; // Admin need to refresh
        });
    }

    static initializeApiConnection() {
        // TODO: centralize this
        apiClient.ajaxHandler.addPostprocessor((response, xhrPromise) => {
            const {error} = response;
            if (error) {
                throw error;
            }
        });

        /// For now, always show a download pop-up
        apiClient.ajaxHandler.addPostprocessor((response, xhrPromise) => {
            const {attachment} = response;
            if (!attachment) {
                return;
            }
            // Do this is another flow, to not interrupt logic
            setTimeout(() => {
                const {content, fileName, encodingType} = attachment;
                let saveContent = content
                if (encodingType === "base64") {
                    const binaryString = atob(content);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    saveContent = new Blob([bytes.buffer], {type: "octet/stream"});
                }
                FileSaver.saveAs(saveContent, fileName);
            });
        })
    }

    static initializeTheme() {
        registerDashboardStyleSheets();

        // Admin specific theme properties
        Theme.setProperties({
            SIDEBAR_LINK_DEFAULT_COLOR: "#B9CBCD",
            SIDEBAR_LINK_ACTIVE_COLOR: "#FFF",
            SIDEBAR_BACKGROUND: "linear-gradient(257.14deg, #064049 28.76%, #07525E 54.31%, #064049 81.73%, #064049 98.33%)",
        });
    }

    async fetchData() {
        if (!authService.isAuthenticated()) {
            return;
        }

        await apiClient.get("/admin/app_data");
        this.haveLoaded = true;
        this.redraw();
    }

    render() {
        authService.setLoginAppName("admin_dashboard"); // TODO @cleanup why the fuck do you care what my app is?
        if (!authService.isAuthenticated()) {
            return <Router routes={UNAUTH_ROUTES} />;
        }

        if (!this.haveLoaded) {
            return <LoadingSpinner/>;
        }

        const handleLogout = () => authService.logout();
        const styleSheet = DashboardPageStyle.getInstance();

        // This div is included because the global stylesheet is used to a certain type of dom structure. And that
        // dom structure needs an extra div until the side panel / main page.
        return [
            <div>
                <DashboardSidePanel ref={"sidebar"} pages={Object.values(AdminPages)} handleLogout={handleLogout}>
                    <BlinkLogo size={20} color="#DDD"/>
                </DashboardSidePanel>
                <div className={styleSheet.mainPage}>
                    <Router ref="router" routes={ROUTES} style={{margin: "auto"}} onChange={() => {
                        const url = Router.parseURL();
                        this.sidebar.setURL(url[0]);
                    }}/>
                </div>
            </div>
        ]
    }

    onMount() {
        this.fetchData();
    }
}
