import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";

class DashboardTitleStyle extends StyleSheet {
    @styleRule
    title = {
        fontSize: 24,
        fontWeight: 300,
        paddingTop: 20,
    }

    @styleRule
    description = {
        fontSize: 16,
        paddingTop: 8,
        paddingBottom: 16,
        color: this.themeProps.TEXT_SECONDARY_COLOR,
    }
}

// TODO @cleanup properly configure and use this
@registerStyle(DashboardTitleStyle)
export class DashboardDescription extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.description);
    }
}


@registerStyle(DashboardTitleStyle)
export class DashboardTitle extends UI.Element {
    render() {
        const {styleSheet} = this;
        const {title, description} = this.options;
        return [
            <div className={styleSheet.title}> {title} </div>,
            description && <DashboardDescription> {description} </DashboardDescription>
        ]
    }
}
