import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {LocalStorageMap} from "../../stem-core/src/base/StorageMap";
import {Timezone} from "../../stem-core/src/localization/Timezone.js";
import {field} from "../../stem-core/src/state/StoreField.js";

class DashboardSettings extends Dispatchable {
    static storage = new LocalStorageMap("DashboardSettings");

    @field(Timezone) queryTimezone = Timezone.LOCAL;
    @field(Timezone) displayTimezone = Timezone.LOCAL;
    @field(String) dateFormat = "MMM DD, YYYY, h:mm AA";
    @field(String) theme = "Light";

    constructor() {
        super();
        const {storage} = this.constructor;

        for (const fieldDescriptor of this.fieldDescriptors) {
            const {key} = fieldDescriptor;
            const defaultValue = fieldDescriptor.getDefaultValue(this);
            this[key] = storage.get(key, defaultValue);
        }

        storage.addChangeListener((event) => {
            const {key, newValue} = event;
            this[key] = newValue;
            event.value = this[key];
            this.rebuildFormatters();
            this.dispatchChange(event);
        });
        this.rebuildFormatters();
    }

    rebuildFormatters() {
        this.displayDateFormatter = Intl.DateTimeFormat("en-US", {
            timeZone: this.displayTimezone.getTimezoneName(),
            hourCycle: "h23",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }
}

export const dashboardSettings = new Proxy(new DashboardSettings(), {
    get: (settings, key, receiver) => {
        return settings[key] || settings.constructor.storage.get(key);
    },
    set: (settings, key, value) => {
        if (value != null) {
            settings[key] = value;
            settings.constructor.storage.set(key, settings[key].valueOf()); // Use valueOf() on serialization
        } else {
            // We're deleting the value
            settings.constructor.storage.delete(key);
            settings[key] = settings.constructor.defaultValues;
        }
        settings.rebuildFormatters();
        settings.dispatchChange({key: settings[key]});
        return true;
    }
});


// This might be move somewhere else in the future
export function DashboardDefaultDateFormatter(date, {dateFormatter = dashboardSettings.displayDateFormatter, includeHour = true, includeSecond=false} = {}) {
    const parts = dateFormatter.formatToParts(date);

    let obj = {};
    for (const p of parts) {
        obj[p.type] = p.value;
    }

    let str = `${obj.month} ${obj.day}`;
    if (obj.year != new Date().getFullYear()) {
        str += `, ${obj.year}`;
    }
    str += `, ${obj.hour}:${obj.minute}`;

    return str;
}
