import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {Level} from "../../../stem-core/src/ui/Constants.js";
import {StoreObject} from "../../../stem-core/src/state/Store.js";
import {field} from "../../../stem-core/src/state/StoreField.js";

@makeEnum
export class LoggingLevel extends BaseEnum {
    static DEBUG = 10;
    static INFO = 20;
    static WARNING = 30;
    static ERROR = 40;
    static CRITICAL = 50;
    static UNKNOWN;

    // Slightly different that the regular enum method
    static fromValue(value) {
        for (const entry of this.all()) {
            if (entry.value >= value) {
                return entry;
            }
        }
        return this.UNKNOWN;
    }

    getLabelLevel() {
        if (this.value >= this.constructor.ERROR.value) {
            return Level.ERROR;
        }
        if (this.value >= this.constructor.WARNING.value) {
            return Level.WARNING;
        }
        return Level.INFO
    }
}


export class BaseLogMessage extends StoreObject {
    @field(Date) createdAt;
    @field(LoggingLevel) level;
    @field(Number) code;
    @field(String) message;
    @field(Object) extra;
    @field("Merchant") merchant;
    @field("User") user;
}
