import {isString, titleCase} from "../../stem-core/src/base/Utils";

export function formatXML(xmlString) {
    // Define the output rules
    const xsltDoc = new DOMParser().parseFromString(`<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
          <xsl:strip-space elements="*"/>
          <xsl:template match="para[content-style][not(text())]">
            <xsl:value-of select="normalize-space(.)"/>
          </xsl:template>
          <xsl:template match="node()|@*">
            <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>
          </xsl:template>
          <xsl:output indent="yes"/>
        </xsl:stylesheet>`, "application/xml");

    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);

    const xmlDoc = new DOMParser().parseFromString(xmlString, "application/xml");
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    return new XMLSerializer().serializeToString(resultDoc);
}


export function formatJSON(json, {tabSize=3}={}) {
    if (isString(json)) {
        try {
            json = JSON.parse(json);
        } catch (e) {
            return `JSON parse error: ${e}\n${json}`;
        }
    }
    return JSON.stringify(json, null, tabSize);
}


export function camelCaseToCapitalizedWords(value) {
    return titleCase(value.replace(/[\w]([A-Z])/g, (word) => word[0] + " " + word[1]));
}
