import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {MerchantTableList} from "./MerchantTableList.jsx";
import {MerchantStore} from "../../../client/state/MerchantStore.js";
import {CreateMerchantModal} from "./CreateMerchantModal.jsx";
import {CreateLegalEntityModal} from "./legal-entities/CreateLegalEntityModal.jsx";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {Panel} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {TopLevelTabArea} from "../../../dashboard/common/theme/TabAreaStyle.js";
import {LegalEntityStore} from "../../../client/state/LegalEntityStore.js";
import {DashboardTitle} from "../../../dashboard/common/DashboardTitle.js";
import {SimpleTable} from "../../../dashboard/ui/SimpleTable.jsx";
import {CENTER_COLUMN, DATE_COLUMN, LEFT_COLUMN} from "../../../dashboard/common/theme/TableStyle.js";


class MerchantListStyle extends StyleSheet {
    @styleRule
    actionButtonsContainer = {
        display: "flex",
        flexDirection: "row",
        margin: "10px 0px 10px 0px",

        "> :not(:last-child)": {
           marginRight: 10,
        }
    };
}

@autoredraw(MerchantStore)
@registerStyle(MerchantListStyle)
export class MerchantList extends UI.Element {
    render() {
        const merchants = MerchantStore.all();
        return [
            <div className={this.styleSheet.actionButtonsContainer}>
                <Button onClick={() => CreateMerchantModal.show()}>Create Merchant</Button>
            </div>,
            <MerchantTableList entries={merchants}/>,
        ];
    }
}


@autoredraw(LegalEntityStore)
@registerStyle(MerchantListStyle)
class LegalEntityList extends Panel {
    render() {
        const columns = [
            ["Id", entity => entity.id, LEFT_COLUMN],
            ["Name", entity => entity.name, LEFT_COLUMN],
            ["Legal name", entity => entity.legalName, LEFT_COLUMN],
            ["Created At", entity => entity.createdAt, DATE_COLUMN],
            ["Card statement name", entity => entity.cardStatementName, CENTER_COLUMN],
            ["Owner Id", entity => entity.ownerId, CENTER_COLUMN], // TODO: get the email here
        ];

        const entries = LegalEntityStore.all();

        return [
            <div className={this.styleSheet.actionButtonsContainer}>
                <Button onClick={() => CreateLegalEntityModal.show()}>Create Legal Entity</Button>
            </div>,
            <SimpleTable columns={columns} entries={entries} />,
        ]
    }
}

export class AdminMerchantsPage extends UI.Element {
    render() {
        return [
            <DashboardTitle title={"Manage merchants and legal entities"} />,
            <TopLevelTabArea>
                <MerchantList title="Merchants" />
                <LegalEntityList title="Legal Entities" />
            </TopLevelTabArea>
        ]
    }
}
