import {ProgressBarStyle} from "../../../stem-core/src/ui/ProgressBar";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";

export class DashboardProgressBarStyle extends ProgressBarStyle {
    @styleRuleInherit
    container = {
        height: 6,
        marginTop: 6,
        marginBottom: 6,
        borderRadius: this.themeProps.CARD_BORDER_RADIUS,
        boxShadow: null,
    }
}
