import {Dispatchable} from "../../stem-core/src/base/Dispatcher.js";
import {apiClient} from "../../client/connection/BlinkApiClient.js";
import {authService} from "../../client/connection/services/AuthService.js";
import {ApiErrors} from "../../client/connection/ApiErrors";
import {MerchantStore} from "../../client/state/MerchantStore";
import {LocalStorageMap} from "../../stem-core/src/base/StorageMap";

const MERCHANT_ID_STORAGE_KEY = "merchantId";

// TODO: probably redesign how we use this class
export class MerchantService extends Dispatchable {
    finishedMerchantDataRequest = false;
    currentMerchantId = null;

    setCurrentMerchantId(merchantId) {
        try {
            localStorage.setItem(MERCHANT_ID_STORAGE_KEY, merchantId);
        } catch (error) {
        } finally {
            this.currentMerchantId = merchantId;
        }
    }

    getMerchantId() {
        try {
            return parseInt(localStorage.getItem(MERCHANT_ID_STORAGE_KEY));
        } catch (error) {
            return this.currentMerchantId;
        }
    }

    getMerchant() {
        return MerchantStore.getByIdOrAlias(this.getMerchantId());
    }

    getLocalStorageMap() {
        return new LocalStorageMap(this.getMerchantId());
    }

    async fetchMerchantData() {
        try {
            const response = await apiClient.get("/merchant/merchant_data", {});

            return response;
        } catch (error) {
            if (error.code === ApiErrors.AUTHENTICATION_FAILED || error.code === ApiErrors.NOT_AUTHENTICATED) {
                authService.clearCurrentInfo();
            }
            throw error;
        } finally {
            this.updateMerchantId();
            this.finishedMerchantDataRequest = true;
            this.dispatch("merchantDataRequestFinished");
        }
    }

    updateMerchantId() {
        // Save merchantId only when the logged in user has access to a single merchant.
        const merchants = MerchantStore.all();
        if (merchants.length === 1) {
            this.setCurrentMerchantId(merchants[0].id);
        }
    }

    isMerchantDataRequestFinished() {
        return this.finishedMerchantDataRequest;
    }
}

// TODO @Mihai @cleanup stop using this!!!!
export const merchantService = new MerchantService();
