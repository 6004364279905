import {UI} from "../../../../../../stem-core/src/ui/UIBase";
import {DateCalendarInputInterface} from "../../base/DateCalendarInputInterface";
import {CURRENT_PICK} from "../../DateRangePickerConstants";
import {DateRangeCalendarMonthInput} from "../components/DateRangeCalendarMonthInput";


export class DateRangeCalendarInput extends DateCalendarInputInterface {
    setValue(value) {
        if (!this.value || (value.startDate === this.value.startDate && value.endDate === this.value.endDate
            && value.currentPick === this.value.currentPick)) {

            this.clearAnimations();
            this.referenceTime = value.currentPick === CURRENT_PICK.startDate ? value.startDate : value.endDate;

            super.setValue(value);
            return;
        }

        if (value.startDate === this.value.startDate && value.endDate !== this.value.endDate
            && value.currentPick === CURRENT_PICK.startDate) {
            this.swipe(value.startDate);
        } else if (value.startDate !== this.value.startDate && value.endDate === this.value.endDate
            && value.currentPick === CURRENT_PICK.endDate) {
            this.swipe(value.endDate);
        } else {
            this.referenceTime = value.currentPick === CURRENT_PICK.startDate ? value.startDate : value.endDate;
        }

        super.setValue(value);
    }

    renderCalendarMonthComponent(monthDate) {
        const {minDate, maxDate} = this.options;
        return <DateRangeCalendarMonthInput initialValue={this.value} {...{minDate, maxDate, monthDate}}
                                            onChange={value => this.setAndDispatchValue(value)}
                                            className={this.styleSheet.month} />;
    }
}
