import {UI} from "stem-core/src/ui/UI.js";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal.jsx";
import {CardItems} from "../ui/CardItems.jsx";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Modal} from "../../../blinkpay/ui/Modal.jsx";
import {apiClient} from "../../../client/connection/BlinkApiClient.js";
import {Button} from "stem-core/src/ui/UI.js";
import {formatQueryParam} from "../../../blinkpay/UtilsLib.js";
import {BLINK_PAY_URL} from "../../../blinkpay/Constants.js";
import {UserSessionStore} from "../../../client/state/UserSessionStore.js";
import {CopyTextToClipboard} from "../../../dashboard/common/Utils.js";


export class AnonymizeUserModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Anonymize User",
            description: "Warning! This operation is not reversible. All user data will be anonymized.",
            confirmLabel: "Anonymize user",
            confirmAction: () => this.anonymize(),
        }
    }

    async anonymize() {
        const {user} = this.options;

        await this.makeRequest("/admin/users/anonymize_account/", {userId: user.id});
    }

    render() {
        const {user} = this.options;

        if (this.waitingForResponse) {
            return <LoadingSpinner/>;
        }

        return [
            <CardItems items={[
                ["Email", user.email],
                ["Type", user.userType],
                ["Date Joined", user.createdAt],
                ["Last Login", user.lastLogin]
            ]}
            />,
        ];
    }
}

export class ImpersonateUserModal extends Modal {
    userToken = null;

    async generateToken() {
        const {user} = this.options;
        const request = {
            userId: user.id,
        };

        const response = await apiClient.post("/admin/users/fake_user_session/", request);
        this.userToken = UserSessionStore.loadObject(response);
        this.redraw();
    }

    getTokenSection() {
        const {userToken} = this;
        if (!userToken) {
            return null;
        }

        const cookieToken = {
            t: userToken.key,
            e: userToken.expiresAt,
        };

        const newSessionLinkURL = `${BLINK_PAY_URL}/set-cookie/?token=${formatQueryParam(cookieToken)}&redirectUrl=${formatQueryParam("/wallet/")}`;
        return [
            <div style={{margin: 20}}>
                User token
                <div>{userToken.key}</div>
            </div>,
            <div style={{margin: 20}}>
                <a href={newSessionLinkURL}>Link to set in new session</a>
            </div>,
            <Button onClick={() => CopyTextToClipboard(newSessionLinkURL)} label="Copy link"/>,
        ];
    }


    render() {
        return [
            <div>
                <div>
                <Button onClick={() => this.generateToken()} label="Generate Token" />
                </div>
                {this.getTokenSection()}
            </div>
        ]
    }
}
