import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {DashboardTitle} from "../../../dashboard/common/DashboardTitle.js";
import {APIServerStats, TaskQueueStats, BackendDatabaseStats} from "./APIServerStats.jsx";

export class AdminHomePage extends UI.Element {
    render() {
        return [
            <DashboardTitle title="General statistics" />,
            <APIServerStats collapsed={true} />,
            <BackendDatabaseStats collapsed={true} />,
            <TaskQueueStats collapsed={true} />,
        ];
    }
}
