import {FlatTabAreaStyle} from "../../../stem-core/src/ui/tabs/Style";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {FlatTabArea} from "../../../stem-core/src/ui/tabs/FlatTabArea";

export class BlinkTabAreaStyle extends FlatTabAreaStyle {
    @styleRuleInherit
    nav = {
        borderTopLeftRadius: this.themeProps.TAB_AREA_BORDER_RADIUS,
        borderTopRightRadius: this.themeProps.TAB_AREA_BORDER_RADIUS,
        backgroundColor: this.themeProps.CARD_HEADER_BACKGROUND_COLOR,
    }

    @styleRuleInherit
    switcher = {
        border: "1px solid #E5EAE9",
        borderTop: 0,
        borderBottomLeftRadius: this.themeProps.TAB_AREA_BORDER_RADIUS,
        borderBottomRightRadius: this.themeProps.TAB_AREA_BORDER_RADIUS,
    }
}

export class BorderlessTabAreaStyle extends FlatTabAreaStyle {
    @styleRuleInherit
    nav = {
        backgroundColor: null,
    }

    @styleRuleInherit
    tab = {
        backgroundColor: null,
        fontSize: 14,
    }

    @styleRuleInherit
    switcher = {
        paddingTop: 16,
        overflow: null,
    }
}

@registerStyle(BorderlessTabAreaStyle)
export class TopLevelTabArea extends FlatTabArea {}
