import {UI} from "../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {BlinkInput} from "../../../blinkpay/ui/Input";
import {apiClient} from "../../../client/connection/BlinkApiClient.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore";


export class MerchantAddUserModal extends ConfirmationModal {
    inputRefs = {};

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Add user",
            description: "Will create a user entry which you can grant subscription towards or subscribe to email newsletter, etc.",
            confirmLabel: "Confirm",
            onSuccess: NOOP_FUNCTION,
            confirmAction: () => this.addUser(),
        };
    }

    validateInputs() {
        let isValid = true;

        for (const [key, fieldInput] of Object.entries(this.inputRefs)) {
            fieldInput.validate();
            isValid = isValid && fieldInput.isValid();
        }

        return isValid;
    }

    @wrapInSpinner
    async addUser() {
        if (!this.validateInputs()) {
            return;
        }
        const request = {
            emailAddress: this.inputRefs.emailInput.getValue().trim(),
            firstName: this.inputRefs.firstNameInput.getValue().trim(),
            lastName: this.inputRefs.lastNameInput.getValue().trim(),
        }

        const response = await apiClient.post("/merchant/create_user", request);
        const merchantUser = MerchantUserStore.loadObject(response);
        this.options.onSuccess(merchantUser);
        this.hide();
    }

    render(){
        return <div>
            <BlinkInput ref={{parent: this.inputRefs, name:"emailInput"}}
                        label={"Email"}
                        inputAttributes={{placeholder: "Email"}}
                        validators={[{condition: () => !this.inputRefs.emailInput.isEmpty()}]}
            />
            <BlinkInput ref={{parent: this.inputRefs, name:"firstNameInput"}}
                        label={"First Name (Optional)"}
                        inputAttributes={{placeholder: "First Name"}}
            />
            <BlinkInput ref={{parent: this.inputRefs, name:"lastNameInput"}}
                        label={"Last Name (Optional)"}
                        inputAttributes={{placeholder: "Last Name"}}
            />
        </div>
    }
}
