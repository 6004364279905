import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {Card} from "../../ui/Card.jsx";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable.jsx";
import {FormatPaymentMethodDescription} from "../../../../blinkpay/common/PaymentMethodDescription.jsx";


export class UserPaymentMethodList extends UI.Element{
    render() {
        const {user} = this.options;
        const userPaymentMethods = user.getPaymentMethods();
        const columns = [
            ["Id", paymentMethod => paymentMethod.id],
            ["Provider", paymentMethod => paymentMethod.provider],
            ["Payment method", paymentMethod => FormatPaymentMethodDescription(paymentMethod)],
            ["Is Default", obj => obj.isPrimary ? "true" : "false"],
            ["Created at", obj => obj.createdAt],
        ]
        return (
            <Card title="Payment Methods">
                <SimpleTable entries={userPaymentMethods} columns={columns} />
            </Card>
        );
    }
}
