import {field} from "../../stem-core/src/state/StoreField.js";
import {Store, StoreObject} from "../../stem-core/src/state/Store";
import {apiClient} from "../connection/BlinkApiClient.js";
import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";


@makeEnum
export class EmailTemplateType extends BaseEnum {
    static CUSTOM; // A custom merchant defined template
    static CAMPAIGN;
    static AUTH_LOGIN_CODE;
    static CHANGE_EMAIL_CONFIRM;
    static CHANGE_EMAIL_UNDO;
    static DONATION_SUCCESS;
    static DONATION_CANCEL;
    static SUBSCRIPTION_SUCCESS;
    static SUBSCRIPTION_GIFT_SUCCESS_BILLED_USER;
    static SUBSCRIPTION_GIFT_SUCCESS_BENEFICIARY;
}


class EmailTemplate extends StoreObject {
    @field(String) name;
    @field(EmailTemplateType) type;
    @field("Merchant") merchant;
    @field("MerchantEmailSender") emailSender;
    @field("MerchantEmailCampaign") campaign;
    @field(String) replyTo;
    @field(Array) bccEmails;
    @field(String) subject;
    @field(String) html;
    @field(String) plainText;
    @field(Boolean) isTypeDefault;

    toString() {
        return this.name;
    }
}

class EmailTemplateStoreClass extends Store("EmailTemplate", EmailTemplate) {
}

export const EmailTemplateStore = new EmailTemplateStoreClass();

export async function apiCreateEmailTemplate(request) {
    const response = await apiClient.post("/mailing/create_template", request);
    return EmailTemplateStore.loadObject(response);
}

export async function apiUpdateEmailTemplate(request) {
    return apiClient.post("/mailing/update_template", request);
}

export async function apiRenderEmailTemplate(data) {
    return apiClient.post("/mailing/render_template", data);
}

export async function apiSendTestEmailTemplate(data) {
    return apiClient.post("/mailing/send_test_email", data);
}
