import {UI} from "stem-core/src/ui/UIBase.js";
import {Card} from "../ui/Card.jsx";
import {SimpleTable} from "../../../dashboard/ui/SimpleTable.jsx";
import {autoredraw} from "stem-core/src/decorators/AutoRedraw.js";
import {MerchantStaffStore} from "client/state/merchant/MerchantStaffStore.js";


@autoredraw(MerchantStaffStore)
export class MerchantStaffList extends UI.Element {
    render() {
        const {merchant} = this.options;

        const usersAccess = merchant.getUsersAccess();
        const accessTableColumns = [
            ["User", userAccess => userAccess.user.email],
            ["Is Owner", userAccess => userAccess.isOwner],
        ]

        return (
            <Card title="Dashboard access">
                <SimpleTable entries={usersAccess} columns={accessTableColumns}/>
            </Card>
        )
    }
}
