import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {EndpointPaginator} from "../../../client/state/EndpointPaginator.js";
import {CDSRequestStore} from "../../../client/state/merchant/external-providers/CDSRequestsStore.js";
import {Link} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {DashboardTitle} from "../../../dashboard/common/DashboardTitle.js";
import {SimpleTable} from "../../../dashboard/ui/SimpleTable.jsx";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {DashboardModal} from "../../../dashboard/common/modals/DashboardModal.jsx";
import {MerchantCDSProductStore} from "../../../client/state/merchant/external-providers/MerchantCDSProductStore.js";
import {BlinkInputField} from "../../../dashboard/common/Input.jsx";
import {Select} from "../../../stem-core/src/ui/input/Input.jsx";
import {DashboardStaticText} from "../../../dashboard/common/DashboardStaticText.jsx";
import {TextInput} from "../../../stem-core/src/ui/input/Input.jsx";

function GetCDSMerchants() {
    const merchantSet = new Set(MerchantCDSProductStore.all().map(cdsProduct => cdsProduct.merchant));
    return Array.from(merchantSet);
}


class OptionalBoolInput extends Select {
    static ANY = "Any";
    static TRUE = "Yes";
    static FALSE = "No";

    getDefaultOptions() {
        return {
            options: [
                this.constructor.ANY,
                this.constructor.TRUE,
                this.constructor.FALSE,
            ]
        }
    }

    getValue() {
        const value = super.getValue();
        if (value === this.constructor.TRUE) {
            return true;
        }
        if (value === this.constructor.FALSE) {
            return false;
        }
        return undefined;
    }
}


class CDSRequestInstanceModal extends DashboardModal {
    render() {
        const {cdsRequest} = this.options;
        const {requestPayload} = cdsRequest;
        return [
            <div>
                Request id: {cdsRequest.id}
            </div>,
            <div>
                URL: {cdsRequest.url}
            </div>,
            <div>
                Duration: {cdsRequest.responseElapsedMs}ms
            </div>,

            requestPayload ? [<h1>Request payload (masked):</h1>,
                <DashboardStaticText type="xml" value={requestPayload}/>] : <h1>No request body</h1>,

            <h1>Response payload:</h1>,
            <DashboardStaticText type="json" value={cdsRequest.responsePayload} />
        ]
    }
}

export class CDSRequestsPage extends UI.Element {
    paginator = new EndpointPaginator(CDSRequestStore, "/external_providers/cds_requests_list/", {
        pageSize: 25,
        merchantId: GetCDSMerchants()[0].id,
    });

    render() {
        const columns = [
            ["User", request => {
                const {user} = request;
                if (!user) {
                    return "None";
                }
                return <Link href={`/users/${user.id}`}>{user.getEmail()}</Link>
            }],
            ["Created At", request => request.createdAt],
            ["CDS Product", request => request.product],
            ["Method", request => request.method],
            ["URL", request => request.url],
            ["Request Size", request => request.requestPayload?.length || "-"],
            ["Response Code", request => request.responseCode || "-"],
            ["Duration", request => request.responseElapsedMs ? (request.responseElapsedMs + "ms") : "-"],
            ["Response Size", request => request.responsePayload?.length || "-"],
            ["Success", request => (request.responseCdsIsSuccess && !request.exceptionInfo) ? "Yes" : "No"],
            ["Sent to Offline", request => request.responseSentToOffline ? "Yes" : "No"],
            ["", request => <Button onClick={() => CDSRequestInstanceModal.show({cdsRequest: request, fillScreen: true})}>See details</Button>]
        ];

        return [
            <DashboardTitle title={"CDS Requests"}/>,
            <div>
                <BlinkInputField label="Merchant">
                    <Select options={GetCDSMerchants()} onChange={(event, input) => this.paginator.updateFilter({merchantId: input.getValue().id})}/>
                </BlinkInputField>
                <BlinkInputField label="User (email/name)">
                    <TextInput
                        onChange={(event, input) => this.paginator.updateFilter({nameOrEmailSearch: input.getValue()})}
                    />
                </BlinkInputField>
                <BlinkInputField label="Method">
                    <Select
                        options={["Any", "GET", "POST", "PUT"]}
                        onChange={(event, input) => {
                            let method = input.getValue();
                            if (method == "Any") {
                                method = null;
                            }
                            this.paginator.updateFilter({method});
                        }}
                        />
                </BlinkInputField>
                <BlinkInputField label="CDS Success">
                    <OptionalBoolInput
                        onChange={(event, input) => this.paginator.updateFilter({cdsIsSuccess: input.getValue()})}
                    />
                </BlinkInputField>
                <BlinkInputField label="Sent To Offline">
                    <OptionalBoolInput
                        onChange={(event, input) => this.paginator.updateFilter({sentToOffline: input.getValue()})}
                    />
                </BlinkInputField>
                <BlinkInputField label="Have exception">
                    <OptionalBoolInput
                        onChange={(event, input) => this.paginator.updateFilter({haveException: input.getValue()})}
                    />
                </BlinkInputField>
            </div>,
            <SimpleTable paginator={this.paginator} columns={columns}/>,
        ];
    }
}
