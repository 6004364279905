import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {TimeUnit} from "../../../stem-core/src/time/Duration";
import {Money} from "../../../stem-core/src/localization/Money.js";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../connection/BlinkApiClient.js";
import {FileResource} from "./FileResourceStore";


class MerchantPayout extends StoreObject {
    @field("Merchant") merchant;
    @field("Currency") currency;
    @field(Money) paidAmount;
    @field(Date) createdAt;
    @field(Date) lastPaymentDate;
    @field(FileResource) pdfReport;
    @field(FileResource) paymentsReport;
    @field(FileResource) accountingReport;
    @field(FileResource) zipArchive;

    getName() {
        const {lastPaymentDate} = this;
        const period = lastPaymentDate.subtract(TimeUnit.DAY).format("YYYY-MM");
        return `${period} Payout`;
    }
}

export const MerchantPayoutStore = MakeStore("MerchantPayout", MerchantPayout);

export async function apiAdminGenerateStripeReport() {
    const response = await apiClient.post("/admin/payouts/generate_stripe_report/");
    return MerchantPayoutStore.load(response);
}

export async function apiAdminGetPayoutMonthSummaryReport() {
    const response = await apiClient.post("/admin/payouts/get_monthly_report/");
    return MerchantPayoutStore.load(response);
}

export async function apiAdminGeneratePayouts() {
    const response = await apiClient.post("/admin/payouts/generate_monthly_report/");
    return MerchantPayoutStore.load(response);
}

export async function apiAdminApprovePayout(payoutId) {
    return await apiClient.post("/admin/payouts/approve/", {payoutId})
}
