import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {Level} from "../../stem-core/src/ui/Constants";
import {InfoContentPopup} from "./InfoTooltip.jsx";
import {Color} from "../../stem-core/src/ui/Color.js";
import {RandGen} from "../../stem-core/src/numerics/Random.js";

class DashboardLabelStyle extends StyleSheet {
    @styleRule
    container = {
        display: "inline-block",
        color: "white",
        borderRadius: 4,
        background: this.themeProps.COLOR_SUCCESS,
        padding: 4,
        paddingBottom: 2, // Looks better
        margin: 2,
        fontSize: 13,
        fontWeight: "bold",
    }

    @styleRule
    info = {
        background: "#DDD",
        color: "#405050", // TODO use themeProps
    }

    @styleRule
    warning = {
        background: this.themeProps.COLOR_WARNING,
    }

    @styleRule
    error = {
        background: this.themeProps.COLOR_DANGER,
    }
}

@registerStyle(DashboardLabelStyle)
export class DashboardLabel extends UI.Element {
    extraNodeAttributes(attr) {
        const {level, tooltip, autocolor} = this.options;
        if (level === Level.INFO) {
            attr.addClass(this.styleSheet.info);
        }
        if (level === Level.WARNING) {
            attr.addClass(this.styleSheet.warning);
        }
        if (level === Level.ERROR) {
            attr.addClass(this.styleSheet.error);
        }
        if (tooltip) {
            attr.setStyle({
                cursor: "help",
            });
        }

        let {color} = this.options;
        if (autocolor) {
            const {label} = this.options;
            const labelRNG = new RandGen(label);
            const firstColor = labelRNG.pick(["gray", "#07525E"]);
            const secondColor = labelRNG.pick(["purple", "cornflowerblue", "yellow"]);
            color = Color.interpolate(firstColor, secondColor, 0.1 + labelRNG.random() * 0.8);
        }

        if (color) {
            attr.setStyle({
                background: color,
            });
        }
    }

    showPopup() {
        const {tooltip} = this.options;
        if (tooltip) {
            InfoContentPopup.show({
                anchor: this,
                content: tooltip,
            });
        }
    }

    render() {
        const {label} = this.options;
        return label || super.render();
    }

    onMount() {
        for (const eventType of ["mouseenter", "tap"]) {
            this.addNodeListener(eventType, () => this.showPopup());
        }
    }
}
