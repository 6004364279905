// UI to look over all API endpoints, do some filtering, etc.
import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {SortableTable} from "../../../stem-core/src/ui/table/SortableTable.jsx";
import {Table, TableRow} from "../../../stem-core/src/ui/table/Table.jsx";
import {Modal} from "../../../stem-core/src/ui/modal/Modal.jsx";
import {Select, TextInput} from "../../../stem-core/src/ui/input/Input.jsx";
import {fetch} from "../../../stem-core/src/base/Fetch.js";
import {Label} from "../../../stem-core/src/ui/SimpleElements.jsx";
import {Level, Size} from "../../../stem-core/src/ui/Constants.js";

const CENTER_COLUMN_STYLE = {
    textAlign: "center",
    margin: "auto",
    verticalAlign: "middle",
};

class APIEndpointEntry {
    constructor(rawObj) {
        Object.assign(this, rawObj);
    }

    getThrottleStr() {
        let str = "";
        for (const rate of this.throttle.rates) {
            if (str.length) {
                str += ",";
            }
            str += rate.count + "/" + rate.duration + "s";
        }
        return this.throttle.scope + " (" + str + ")";
    }

    getThrottleValue() {
        const rate = this.throttle.rates[0];
        return rate.count / rate.duration;
    }
}

const TABLE_COLUMNS = [{
    headerName: "Endpoint",
    rawValue: (entry) => entry.path,
    value: (entry) => {
        let result = [<code>{entry.path}</code>];
        if (entry.deprecated) {
            result = [
                <Label level={Level.WARNING} domTitle={entry.deprecation_message}>Deprecated</Label>,
                ...result
            ]
        }
        return result;
    },
    headerStyle: {verticalAlign: "middle"},
}, {
    headerName: "Method",
    rawValue: (entry) => entry.method,
    value: (entry) => <code>{entry.method}</code>,
    headerStyle: {verticalAlign: "middle"},
    cellStyle: CENTER_COLUMN_STYLE,
}, {
    headerName: "Permissions",
    value: (entry) => entry.permissions,
    headerStyle: {verticalAlign: "middle"},
    cellStyle: CENTER_COLUMN_STYLE,
}, {
    headerName: "Throttles",
    rawValue: entry => entry.getThrottleValue(), // TODO: calculate
    value: entry => <code>{entry.getThrottleStr()}</code>,
    headerStyle: {verticalAlign: "middle"},
    cellStyle: CENTER_COLUMN_STYLE,
}, {
    headerName: "Num examples",
    value: (entry) => entry.examples.length,
    headerStyle: {verticalAlign: "middle"},
    cellStyle: CENTER_COLUMN_STYLE,
}];

const SCHEMA_TABLE_COLUMNS = [{
    headerName: "Key",
    value: (entry) => entry.key,
    headerStyle: {verticalAlign: "middle"},
}, {
    headerName: "Type",
    value: (entry) => {
        const {type} = entry;
        if (!type) {
            return <code>Err: No type</code>;
        }

        let result = type;

        for (const [key, value] of Object.entries(entry)) {
            if (key != "key" && key != "type" && key != "required") {
                result += " " + key + "=" + JSON.stringify(value);
            }
        }

        return <code>{result}</code>;
    },
    headerStyle: {verticalAlign: "middle", textTransform: "capitalize"},
}, {
    headerName: "Required",
    value: (entry) => entry.required ? "Yes" : "No",
    headerStyle: {verticalAlign: "middle"},
    cellStyle: CENTER_COLUMN_STYLE,
}]

export class APIEndpointDetailsModal extends Modal {
    getInterface() {
        const {entry} = this.options;
        const {schema} = entry;

        let keys = Object.entries(schema || {});
        keys = keys.map((arr) => {
            return {
                ...arr[1],
                key: arr[0]
            };
        });

        return [
            keys.length ? [<h2>Interface:</h2>, <Table columns={SCHEMA_TABLE_COLUMNS} entries={keys} />] : <h2>No arguments</h2>,
        ]
    }

    getDeprecationMessage() {
        const {deprecated, deprecation_message} = this.options.entry;
        if (!deprecated) {
            return null;
        }
        return <div>
            <Label level={Level.WARNING} size={Size.LARGE}>Deprecated</Label>
            <span style={{fontSize: 16, padding: "0.5em"}}>{deprecation_message}</span>
        </div>
    }

    getExamples() {
        const {examples} = this.options.entry;
        if (!examples || examples.length === 0) {
            return <h2>No examples available</h2>
        }

        let result = [
            <h2>{examples.length} example calls</h2>
        ];

        const formatJSON = (obj) => {
            const str = JSON.stringify(obj, null, 3);
            return <pre>{str}</pre>;
        }

        for (let index = 0; index < examples.length; index += 1) {
            const example = examples[index];
            result.push(<h1>Example {index}</h1>,
                <strong>Request body</strong>,
                formatJSON(example.body),
                <strong>Response (status code {example.status_code})</strong>,
                formatJSON(example.response),
                <strong>Headers:</strong>,
                formatJSON(example.headers),
                )
        }
        return result;
    }

    render() {
        const {entry} = this.options;
        return [
            <h1><code>{entry.path}</code></h1>,
            <strong>Throttle: {entry.throttle_rates}</strong>,
            this.getDeprecationMessage(),
            this.getInterface(),
            this.getExamples(),
        ]
    }
}

export class APIEndpointSummaryRow extends TableRow {
    showModal() {
        APIEndpointDetailsModal.show({
            ...this.options,
            fillScreen: true,
        });
    }

    onMount() {
        super.onMount();
        this.addClickListener(() => this.showModal());
    }
}

export class APIEndpointsSummaryTable extends SortableTable {
    getDefaultOptions() {
        return {
            entries: [],
            columns: TABLE_COLUMNS,
        }
    }

    getRowClass() {
        return APIEndpointSummaryRow;
    }
}

export class APIEndpointsFilter extends UI.Primitive("span") {
    render() {
        let methodOptionsSet = new Set();
        for (const entry of this.getEntries()) {
            methodOptionsSet.add(entry.method);
        }

        const updateFilter = () => this.updateFilter();

        return [<Select ref="rightsSelect" options={["All", "Admin", "Merchant", "Authenticated", "Any"]}
                        style={{height: "2.2em", margin: 10}}
                        onChange={updateFilter}/>,
            <Select ref="methodSelect" options={["All", ...Array.from(methodOptionsSet)]}
                        style={{height: "2.2em", margin: 10}}
                        onChange={updateFilter}/>,
            <TextInput ref="findInput" style={{height: "2.03em", margin: 10, verticalAlign: "middle"}}
                       placeholder="Search in endpoints..."
                       onChange={updateFilter} />,
            <strong>{this.getFilteredEntries().length} / {this.getEntries().length} entries</strong>

        ];
    }

    filterEntry(entry) {
        function matchesFilter(str, filter) {
            if (filter != "All" && filter != "") {
                return String(str).indexOf(filter) != -1;
            }
            return true;
        }

        return matchesFilter(entry.permissions, this.rightsSelect.getValue()) &&
               matchesFilter(entry.method, this.methodSelect.getValue()) &&
               matchesFilter(entry.path, this.findInput.getValue());
    }

    updateFilter() {
        this.filteredEntries = this.getEntries().filter((entry) => this.filterEntry(entry));
        this.redraw();
        this.dispatch("change");
    }

    setEntries(entries) {
        this.updateOptions({entries});
        this.dispatch("change");
    }

    getEntries() {
        return this.options.entries;
    }

    getFilteredEntries() {
        return this.filteredEntries || this.getEntries();
    }

    onMount() {
        // TODO: should have an onChange for TextInput
        this.findInput.addNodeListener("keyup", () => this.updateFilter());
    }
}

export class APIEndpointsSummary extends UI.Element {
    getDefaultOptions() {
        return {
            url: "/resources/blink_endpoints_with_examples.json",
        }
    }

    extraNodeAttributes(attr) {
        attr.setStyle({
            margin: "1em",
        });
    }

    getEntries() {
        return this.options.entries || [];
    }

    render() {
        const entries = this.getEntries();
        return [
            <APIEndpointsFilter ref="filter" entries={entries}/>,
            <APIEndpointsSummaryTable ref="table" entries={this.getFilteredEntries()} />
        ]
    }

    getFilteredEntries() {
        return this.filter?.getFilteredEntries();
    }

    async updateData(url) {
        let entries = await fetch(url, {
            method: "GET",
            dataType: "json",
            headers: this.options.headers || {},
        });
        entries = entries.map(entry => new APIEndpointEntry(entry));
        this.updateOptions({entries});
        this.filter?.updateFilter();
    }

    onMount() {
        this.updateData(this.options.url);
        this.filter.addListener("change", () => {
            const entries = this.filter.getFilteredEntries();
            this.table.setEntries(entries);
        });
    }
}
