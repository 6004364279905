import {UI, registerStyle, styleRule} from "stem-core/src/ui/UI.js";
import {StyleSheet} from "stem-core/src/ui/Style.js";


class CardItemsStyle extends StyleSheet {
    @styleRule
    cardItem = {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(27,126,140,0.18)",
        padding: "2px 0",
        fontSize: 14,
    };
}

@registerStyle(CardItemsStyle)
export class CardItems extends UI.Element {
    render() {
        const {items} = this.options;

        return items.map((item, index) => (
            <div key={index} className={this.styleSheet.cardItem}>
                <div>{item[0]}</div>
                <div>{String(item[1] || "-")}</div>
            </div>
        ));
    }
}
