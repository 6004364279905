import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../connection/BlinkApiClient.js";


class MerchantEmailingSettings extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantEmailSender") defaultSender;
    @field(String) emailReplyTo;
    @field(Array) bccEmails;
    @field(Boolean) sendDonationCancelEmail;
}

export const MerchantEmailingSettingsStore = MakeStore("MerchantEmailingSettings", MerchantEmailingSettings);

export async function apiUpdateMerchantEmailingSettings(request) {
    return apiClient.post("merchant/update_emailing_settings/", request);
}

export async function apiMerchantEmailingBreakdown(request) {
    return apiClient.get("/merchant/get_emailing_breakdown/", request);
}
