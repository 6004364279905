import {UI, styleRule, StyleSheet, registerStyle, Theme} from "../../stem-core/src/ui/UI";
import {CheckmarkIcon} from "../../core/ui/SVGElements";

class SingleStepStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        alignItems: "center",
        position: "relative"
    }

    @styleRule
    stepCircle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: 28,
        height: 28,
        border: "1px solid #000",
        borderRadius: "50%",
        backgroundColor: this.themeProps.BUTTON_PRIMARY_BACKGROUND,
        color: this.themeProps.BUTTON_PRIMARY_COLOR,
        fontSize: 12
    }

    @styleRule
    label = {
        position: "absolute",
        top: 28,
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: 14,
        color: this.themeProps.TEXT_PRIMARY_COLOR,
    }

    @styleRule
    line = {
        height: 1,
        width: 100,
        backgroundColor: "#000"
    }
}

@registerStyle(SingleStepStyle)
class SingleStep extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            activeStepIndex: 1,
            stepIndex: 0,
            islastStep: false,
            label: "Label"
        };
    }

    render() {
        const {styleSheet} = this;
        const {activeStepIndex, stepIndex, isLastStep, label} = this.options;

        const stepSymbol = (stepIndex < activeStepIndex || activeStepIndex === -1) ? <CheckmarkIcon size={10} color={Theme.props.BUTTON_PRIMARY_COLOR}/> : stepIndex + 1;
        const opacity = (stepIndex > activeStepIndex && activeStepIndex !== -1) ? "50%" : "100%";

        return [
            <div className={styleSheet.container}>
                <div className={styleSheet.stepCircle} style={{opacity: opacity}}>
                    {stepSymbol}
                    <p className={styleSheet.label}>{label}</p>
                </div>
                {!isLastStep && <div className={styleSheet.line}/>}
            </div>
        ];
    }
}

export class StepIndicatorStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 40
    }
}

@registerStyle(StepIndicatorStyle)
export class StepIndicator extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            activeStep: "Address",
            steps: ["Account", "Address", "Payment"]
        }
    }

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.container);
    }

    render() {
        const {steps, activeStep} = this.options;

        return steps.map((step, index) => <SingleStep
            label={step}
            stepIndex={index}
            isLastStep={index === steps.length - 1}
            activeStepIndex={steps.indexOf(activeStep)}
        />);
    }
}