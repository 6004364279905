import {UI} from "../../stem-core/src/ui/UIBase.js";
import {apiClient} from "../../client/connection/BlinkApiClient.js";
import {DashboardStaticText} from "../common/DashboardStaticText.jsx";
import {DashboardTitle} from "../common/DashboardTitle.js";
import {MerchantStore} from "../../client/state/MerchantStore.js";
import {sleep} from "../common/Utils.js";
import {ToggleInput} from "../../stem-core/src/ui/input/checkbox/ToggleInput.jsx";
import {isString} from "../../stem-core/src/base/Utils.js";

async function loadBlob(merchant) {
    return apiClient.get("/merchant/merchant_data", {merchantId: merchant.id}, {disableStateImport: true, noMerchantOverride: true});
}


export class MerchantConfigBlob extends UI.Element {
    cachedResponses = new Map(); // string key means requested but not resolved, and resolved to the progress message

    async fetchMerchantBlob(merchant) {
        return await loadBlob(merchant);
    }

    async fetchAllMerchantBlobs() {
        const allMerchants = MerchantStore.all();
        const result = {};
        const numTotal = allMerchants.length;
        let numReq = 0;
        for (const merchant of allMerchants) {
            this.cachedResponses.set(null, `Loading ${numReq++} / ${numTotal}`)
            this.redraw();
            const name = String(merchant);
            result[name] = await this.fetchMerchantBlob(merchant);
            await sleep(400); // TODO when making pattern, skip this last sleep
        }
        return result;
    }

    loadRequest(merchant) {
        const existingResponse = this.cachedResponses.get(merchant);
        if (existingResponse) {
            return existingResponse;
        }

        const onLoad = (result) => {
            this.cachedResponses.set(merchant, result);
            this.redraw();
        }

        if (merchant) {
            this.fetchMerchantBlob(merchant).then(onLoad);
        } else {
            this.fetchAllMerchantBlobs().then(onLoad);
        }

        return "Loading...";
    }

    render() {
        const {merchant} = this.options;
        const merchantResponse = this.loadRequest(merchant);

        if (isString(merchantResponse)) {
            // It's the loading message
            return merchantResponse;
        }

        return [
            <DashboardStaticText value={merchantResponse} maxLines={40}/>
        ]
    }
}

export class MerchantConfigBlobPage extends UI.Element {
    render() {
        const allMerchants = MerchantStore.all();
        const {merchant} = this.options;
        const canConsiderAllMerchants = merchant && allMerchants.length > 1;
        const showAllMerchants = this.showAllMerchants?.getValue();

        return [
            <DashboardTitle
                title="Config Blob"
                description="The combined JSON of all the object that the SDK will work with. Mostly usable for debugging and general text search."
            />,

            canConsiderAllMerchants && <div>
                <ToggleInput
                    ref="showAllMerchants"
                    style={{display: "inline-flex"}}
                    initialValue={false}
                    onChange={() => this.redraw()}
                /> Show blobs for all my merchants.
            </div>,

            <MerchantConfigBlob merchant={!showAllMerchants ? merchant : null} />,
        ]
    }
}
