import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {StemDate} from "../../../stem-core/src/time/Date";
import {apiClient} from "../../connection/BlinkApiClient.js";

// Simulate a store for this
export class FileResource extends Dispatchable {
    static cache = new Map();

    constructor(fileResourceId) {
        super();
        if (!fileResourceId) {
            throw "Missing file resource Id";
        }
        this.fileResourceId = fileResourceId;
    }

    async getURL() {
        if (this.response) {
            // TODO handle if the url is expired
            return this.response.url;
        }
        this.response = await apiGetFileResourceURL(this.fileResourceId);
        this.response.expiresAt = new StemDate(this.response.expiresAt);
        return this.response.url;
    }

    static optionally(fileResourceId) {
        if (fileResourceId == null) {
            return null;
        }
        if (!this.cache.has(fileResourceId)) {
            this.cache.set(fileResourceId, new this(fileResourceId));
        }
        return this.cache.get(fileResourceId);
    }

    static makeFieldLoader(fieldDescriptor) {
        fieldDescriptor.rawField = fieldDescriptor.rawField || (key => key + "Id");

        return (value) => this.optionally(value);
    }
}


export async function apiGetFileResourceURL(fileResourceId) {
    return apiClient.get("/merchant/get_file_resource_url", {fileId: fileResourceId});
}
