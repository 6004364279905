import {TableStyle} from "../../../stem-core/src/ui/table/Style";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {StyleSheet} from "../../../stem-core/src/ui/Style";


export const LEFT_COLUMN = {
    cellStyle: {
        textAlign: "left",
    },
    headerStyle: {
        textAlign: "left",
    },
};

export const MONEY_COLUMN = {
    cellStyle: {
        verticalAlign: "middle",
        textAlign: "right",
        fontWeight: "bold",
    },
    headerStyle: {
        textAlign: "right",
    },
};

export const CENTER_COLUMN = {
    cellStyle: {
        textAlign: "center"
    },
    headerStyle: {
        textAlign: "center",
    },
};

export const DATE_COLUMN = {
    cellStyle: {
        ...CENTER_COLUMN.cellStyle,
        width: 180,
    },
    headerStyle: CENTER_COLUMN.headerStyle,
};

export const RESOURCE_ACTIONS_COLUMN = {
    headerStyle: {
        minWidth: 210,
        textAlign: "center",
    }
};

export class BlinkTableStyle extends TableStyle {
    cellStyle = {
        color: () => this.themeProps.TEXT_PRIMARY_COLOR,
        height: 40,
        padding: "6px 14px",
        fontSize: 14,
        verticalAlign: "middle",
        borderTop: "1px solid #E5EAE9",
        textAlign: "left",
    };

    @styleRuleInherit
    container = {
        marginBottom: 0,
        ">*>*>th": {
            ...this.cellStyle,
            borderTop: "",
            color: this.themeProps.TABLE_HEADER_TEXT_COLOR,
            background: this.themeProps.TABLE_HEADER_BACKGROUND_COLOR,
            textTransform: "uppercase",
            verticalAlign: "center",
            fontSize: 12,
        },
        borderRadius: this.themeProps.CARD_BORDER_RADIUS,
        boxShadow: "0 0 0 1px #E5EAE9",
    };

    @styleRuleInherit
    thead = {
        color: this.themeProps.TABLE_HEADER_TEXT_COLOR,
        background: this.themeProps.TABLE_HEADER_BACKGROUND_COLOR,
        fontSize: 12,
        textTransform: "uppercase",
        borderBottom: null,
    }

    @styleRule
    noHeader = {
        " tr:first-child>*": {
            borderTop: "none",
        }
    }

    @styleRule
    tableStripped = {
        ">tbody>tr:nth-of-type(even)": {
            backgroundColor: this.themeProps.TABLE_HEADER_BACKGROUND_ALTERNATE,
        }
    };

    @styleRule
    headerSection = {
        paddingBottom: 8,
        display: "flex",
        alignItems: "center",
    };
}

// TODO refactor this, merge with BlinkTableStyle
export class BlinkTablePaginationStyle extends StyleSheet {
    baseInputStyle = {
        padding: 0,
        paddingLeft: 2,
        paddingRight: 2,
        height: 24,
        outline: 0,
        borderRadius: 0,
        border: "none",
        borderBottom: "1px solid",
        textAlign: "center",

        ":focus": {
            border: "none",
            borderBottom: "1px solid",
            boxShadow: "none",
        },
    }

    @styleRule
    pageControlButton = {
        padding: 0,
        background: "none",
        border: 0,
        outline: 0,
        cursor: "pointer",
        opacity: 0.7,
        ":hover": {
            opacity: "1",
            transition: ".2s",
        },
        ":disabled": {
            opacity: 0.55,
            cursor: "not-allowed",
        },
    };

    @styleRule
    pageNumberInput = {
        ...this.baseInputStyle,
        // Hide the number spinners
        width: 48,
        "-moz-appearance": "textfield",
        "::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        }
    }

    @styleRule
    pageSizeInput = {
        ...this.baseInputStyle,
        marginRight: 20,
    }
}
