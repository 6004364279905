import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {UserSessionStore} from "../../../../client/state/UserSessionStore.js";
import {ArrayPaginator} from "../../../../client/state/EndpointPaginator.js";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable.jsx";
import {Card} from "../../ui/Card.jsx";


export class UserSessionsList extends UI.Element {
    render() {
        const {user} = this.options;
        const userSessions = UserSessionStore.filterBy({userId: user.id});
        const paginator = new ArrayPaginator(userSessions, 8);

        const columns = [
            ["Id", userSession => userSession.id],
            ["Created", userSession => userSession.createdAt],
            ["Ip", userSession => userSession.ip],
            ["Source", userSession => userSession.source],
            ["Social Provider", userSession => userSession.provider],
            ["Country", userSession => userSession.countryCode],
            ["Expire date", userSession => userSession.expiresAt],
            ["Logged out date", userSession => userSession.revokedAt],
            ["Origin", userSession => userSession.originMerchant],
            ["Shared with", userSession => userSession.sharedWithMerchant],
        ];

        return (
            <Card title="Sessions">
                <SimpleTable columns={columns} paginator={paginator}/>
            </Card>
        );
    }
}
