import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../connection/BlinkApiClient.js";


class MerchantFees extends StoreObject {
    @field("Merchant") merchant;
    @field(Number) purchaseFee;
    @field(Number) subscriptionFee;
    @field(Number) donationFee;
}

export async function apiAdminUpdateMerchantFees(data) {
    return apiClient.post("admin/merchants/update_fees/", data);
}

export const MerchantFeesStore = MakeStore("MerchantFees", MerchantFees);
