import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme";
import {styleRule} from "../../../../../stem-core/src/decorators/Style";
import {DateRangeHeader} from "./sections/DateRangeHeader";
import {DateRangeCalendarInput} from "./sections/DateRangeCalendarInput";
import {DashboardBaseInput} from "../../../../../core/ui/input/BaseInput.js";


class DateRangePickerStyle extends StyleSheet {
    @styleRule
    header = {
        padding: "14px 24px 18px",
    };

    @styleRule
    calendar = {
        padding: 24,
        background: this.themeProps.WHITE,
    };

    @styleRule
    dateRangePickerElement = {
        userSelect: "none",
    }
}

@registerStyle(DateRangePickerStyle)
export class DateRangePickerInput extends DashboardBaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            minDate: null,
            maxDate: null,
        }
    }

    isEqual(valueA, valueB) {
        return valueA.startDate === valueB.startDate &&
            valueA.endDate === valueB.endDate &&
            valueA.currentPick === valueB.currentPick;
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.dateRangePickerElement);
    }

    render() {
        const {styleSheet} = this;
        // TODO what is the dispatchTarget? probably delete it
        const {minDate, maxDate, dispatchTarget} = this.options;
        const options = {
            initialValue: this.value,
            onChange: value => {
                // TODO @cleanup currentPick smells
                const {currentPick} = value;
                if (currentPick === 0) {
                    this.setAndDispatchValue(value);
                } else {
                    this.setValue(value);
                }
            },
            minDate, maxDate, dispatchTarget
        };

        return [
            <DateRangeHeader {...options} className={styleSheet.header} ref="header"/>,
            <DateRangeCalendarInput {...options} className={styleSheet.calendar}/>
        ]
    }

    onMount() {
        super.onMount();
        this.header.addListener("pickUpdate", currentPick => {
            this.setValue({...this.value, currentPick});
        })
    }
}
