import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {EndpointPaginator} from "../../../client/state/EndpointPaginator.js";
import {apiAdminCreatePolicy, PolicyTermsStore, PolicyType} from "../../../client/state/PolicyTermsStore.js";
import {SimpleTable} from "../../../dashboard/ui/SimpleTable.jsx";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {Select} from "../../../stem-core/src/ui/input/Input.jsx";
import {CodeEditor} from "../../../stem-core/src/ui/CodeEditor.jsx";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal.jsx";
import {RenderPolicyHTML} from "../../../blinkpay/website/pages/PolicyPage.jsx";
import {Panel} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {TopLevelTabArea} from "../../../dashboard/common/theme/TabAreaStyle.js";
import {DashboardModal} from "../../../dashboard/common/modals/DashboardModal.jsx";

class PolicyTermsPreviewModal extends DashboardModal {
    static preview(html) {
        return this.prompt({html: html, fillScreen: true});
    }

    render() {
        const {html} = this.options;
        return [
            RenderPolicyHTML(html),
        ]
    }
}


class CreatePolicyTerms extends UI.Element {
    async createPolicy() {
        const request = {
            type: this.policyTypeInput.getValue(),
            htmlTemplate: this.htmlInput.getValue(),
        }
        const confirm = await ConfirmationModal.prompt({
            title: "New policy",
            description: `Are you sure you want to update the policy for ${request.type} with ${request.htmlTemplate.length} bytes?`,
        })
        if (!confirm) {
            return;
        }
        await apiAdminCreatePolicy(request);
    }

    preview() {
        const html = this.htmlInput.getValue();
        PolicyTermsPreviewModal.preview(html);
    }

    render() {
        return [
            <div>
                <Select options={PolicyType.all()} ref="policyTypeInput"/>
                <Button onClick={() => this.preview()}>Preview</Button>
                <Button onClick={() => this.createPolicy()}>Create policy</Button>
            </div>,
            <CodeEditor style={{height: 600, width: "100%"}} aceMode="html" ref="htmlInput"/>
        ]
    }
}


@autoredraw(PolicyTermsStore)
export class CompliancePage extends UI.Element {
    paginator = new EndpointPaginator(PolicyTermsStore, "/compliance/policy_list");

    render() {
        const columns = [
            ["ID", policy => policy.id],
            ["Type", policy => policy.type],
            ["Created At", policy => policy.createdAt],
            ["Size", policy => policy.htmlTemplate.length],
            ["", policy => <Button onClick={() => PolicyTermsPreviewModal.preview(policy.htmlTemplate)}>Preview</Button>]
        ];

        return [
            <TopLevelTabArea>
                <Panel title="Policy Templates">
                    <SimpleTable paginator={this.paginator} columns={columns}/>,
                </Panel>
                <CreatePolicyTerms title="New Policy Template" />
            </TopLevelTabArea>
        ];
    }
}
