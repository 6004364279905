import {UI} from "stem-core/src/ui/UI.js";
import {Card} from "../../ui/Card.jsx";
import {CardItems} from "../../ui/CardItems.jsx";
import {AnonymizeUserModal, ImpersonateUserModal} from "../AnonymizeUserModal.jsx";
import {Button} from "stem-core/src/ui/UI.js";


export class UserProfile extends UI.Element {
    onAnonymizeClick() {
        const {user} = this.options;

        AnonymizeUserModal.show({user});
    }

    impersonateUser() {
        const {user} = this.options;
        ImpersonateUserModal.show({user});
    }

    render() {
        const {user} = this.options;

        return (
            <Card title="Profile">
                <CardItems
                    items={[
                        ["Id", user.id],
                        ["Email", user.email],
                        ["First name", user.firstName],
                        ["Last name", user.lastName],
                        ["Type", user.userType],
                        ["Account active", user.isActive ? "true" : "false"],
                        ["Date Joined", user.createdAt],
                        ["Last Login", user.lastLogin],
                        ["Referral merchant", user.referralMerchant?.name],
                    ]}
                />
                <div>
                    <Button onClick={() => this.onAnonymizeClick()} label="Anonymize" />
                    <Button onClick={() => this.impersonateUser()} label="Impersonate" />
                </div>

            </Card>
        );
    }
}
