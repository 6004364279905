import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../stem-core/src/ui/Style";
import {
    dayFormat,
    daysInWeek,
    monthFormat, rangeDayCalendarGradientWidth,
    rangeDayCalendarPadding,
    weekStartingDayIndex
} from "../DateRangePickerConstants";
import {styleRule} from "../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme";
import {StemDate} from "../../../../../stem-core/src/time/Date";
import {NOOP_FUNCTION} from "../../../../../stem-core/src/base/Utils";

class DateRangeCalendarDay2Style extends StyleSheet {
    // first child is the left section
    // second child is the right section
    // third child is the circle containing the label

    section = {
        height: "100%",
        width: "calc(50% + 2px)",
        position: "absolute",
        zIndex: 1,
        top: 0,
    };

    leftSection = {
        ...this.section,
        left: -1,
    };

    rightSection = {
        ...this.section,
        right: -1,
    };

    gradient = {
        width: rangeDayCalendarGradientWidth+1,
        height: "100%",
        position: "absolute",
        top: 0,
    };

    leftGradient = {
        ...this.gradient,
        left: -rangeDayCalendarGradientWidth+1,
    };

    rightGradient = {
        ...this.gradient,
        right: -rangeDayCalendarGradientWidth+1,
    };

    @styleRule
    leftGreySection = {
        ">:first-child": {
            ...this.leftSection,
            background: this.themeProps.DATE_PICKER_DAY_LIGHT_GREY,
        },
        ">:first-child>:first-child": {
            ...this.leftGradient,
            background: `linear-gradient(to right,${this.themeProps.WHITE},${this.themeProps.DATE_PICKER_DAY_LIGHT_GREY})`,
        }
    };

    @styleRule
    leftLightGreenSection = {
        ">:first-child": {
            ...this.leftSection,
            background: this.themeProps.DATE_PICKER_DAY_LIGHT_GREEN,
        },
        ">:first-child>:first-child": {
            ...this.leftGradient,
            background: `linear-gradient(to right,${this.themeProps.WHITE},${this.themeProps.DATE_PICKER_DAY_LIGHT_GREEN})`,
        }
    };

    @styleRule
    leftGreenSection = {
        ">:first-child": {
            ...this.leftSection,
            background: this.themeProps.DATE_PICKER_DAY_MEDIUM_GREEN,
        },
        ">:first-child>:first-child": {
            ...this.leftGradient,
            background: `linear-gradient(to right,${this.themeProps.WHITE},${this.themeProps.DATE_PICKER_DAY_MEDIUM_GREEN})`,
        }
    };

    @styleRule
    rightGreySection = {
        ">:nth-child(2)": {
            ...this.rightSection,
            background: this.themeProps.DATE_PICKER_DAY_LIGHT_GREY,
        },
        ">:nth-child(2)>:first-child": {
            ...this.rightGradient,
            background: `linear-gradient(to right,${this.themeProps.DATE_PICKER_DAY_LIGHT_GREY},${this.themeProps.WHITE})`,
        }
    };

    @styleRule
    rightLightGreenSection = {
        ">:nth-child(2)": {
            ...this.rightSection,
            background: this.themeProps.DATE_PICKER_DAY_LIGHT_GREEN,
        },
        ">:nth-child(2)>:first-child": {
            ...this.rightGradient,
            background: `linear-gradient(to right,${this.themeProps.DATE_PICKER_DAY_LIGHT_GREEN},${this.themeProps.WHITE})`,
        }
    };

    @styleRule
    rightGreenSection = {
        ">:nth-child(2)": {
            ...this.rightSection,
            background: this.themeProps.DATE_PICKER_DAY_MEDIUM_GREEN,
        },
        ">:nth-child(2)>:first-child": {
            ...this.rightGradient,
            background: `linear-gradient(to right,${this.themeProps.DATE_PICKER_DAY_MEDIUM_GREEN},${this.themeProps.WHITE})`,
        }
    };

    @styleRule
    hoverCircle = {
        ">:nth-child(3)": {
            background: this.themeProps.WHITE,
            border: () => ".5px solid " + this.themeProps.DATE_PICKER_DAY_MEDIUM_GREEN,
            boxShadow: () => "inset 0 0 0 2px " + this.themeProps.DATE_PICKER_DAY_MEDIUM_GREEN,
        }
    };

    @styleRule
    greyCircle = {
        ">:nth-child(3)": {
            background: this.themeProps.DATE_PICKER_DAY_DARK_GREY,
        }
    };

    @styleRule
    greenCircle = {
        ">:nth-child(3)": {
            background: this.themeProps.DATE_PICKER_DAY_DARK_GREEN,
        }
    };

    @styleRule
    day = {
        position: "relative",
        width: 38,
        height: 30,
        cursor: "pointer",
        ">:nth-child(3)": {
            position: "absolute",
            zIndex: 2,
            padding: 5,
            borderRadius: 80,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: 30,
            height: 30,
        }
    };

    @styleRule
    lightFont = {
        color: this.themeProps.WHITE,
    };

    @styleRule
    invisible = {
        opacity: 0,
        pointerEvents: "none",
    };

    @styleRule
    leftPadding = {
        ">:first-child": {
            left: `-${rangeDayCalendarPadding-1}px !important`,
            width: `calc(50% + ${rangeDayCalendarPadding+1}px) !important`,
        }
    };

    @styleRule
    rightPadding = {
        ">:nth-child(2)": {
            right: `-${rangeDayCalendarPadding-1}px !important`,
            width: `calc(50% + ${rangeDayCalendarPadding+1}px) !important`,
        }
    };

    @styleRule
    dateUnavailable = {
        pointerEvents: "none",
        opacity: "0.5",
    }
}

@registerStyle(DateRangeCalendarDay2Style)
export class BaseDateCalendarDay extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            date: null,
            monthDate: null,
            startDate: null,
            endDate: null,
            hoverDate: null,
            currentPick: null,
            minDate: null,
            maxDate: null,
            onHover: NOOP_FUNCTION,
            onSelect: NOOP_FUNCTION,
        }
    }

    dateIsInAnotherMonth() {
        const {date, monthDate} = this.options;
        return date.format(monthFormat) !== monthDate.format(monthFormat);
    }

    isEqualTo(inputDate) {
        return this.options.date.format(dayFormat) === inputDate.format(dayFormat);
    }

    isBefore(inputDate) {
        return this.options.date.isBefore(inputDate) && !this.isEqualTo(inputDate);
    }

    isBeforeOrEqual(inputDate) {
        return this.isEqualTo(inputDate) || this.isBefore(inputDate);
    }

    isAfter(inputDate) {
        return this.options.date.isAfter(inputDate) && !this.isEqualTo(inputDate);
    }

    isAfterOrEqual(inputDate) {
        return this.isEqualTo(inputDate) || this.isAfter(inputDate);
    }

    applyDateStyle(attr) {}

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);

        const {styleSheet} = this;
        const {date, minDate, maxDate} = this.options;
        attr.addClass(styleSheet.day);

        // Invisible date case.
        if (this.dateIsInAnotherMonth()) {
            attr.addClass(styleSheet.invisible);
            // invisible
            return;
        }

        if (date.getDay() === weekStartingDayIndex && !this.isFirstDayInMonth()) {
            attr.addClass(styleSheet.leftPadding);
        } else if ((date.getDay() + 1) % daysInWeek === weekStartingDayIndex && !this.isLastDayInMonth()) {
            attr.addClass(styleSheet.rightPadding);
        }

        // Date is out of a given range
        if ((minDate && this.isBefore(minDate)) || (maxDate && this.isAfter(maxDate))) {
            attr.addClass(styleSheet.dateUnavailable);
        }

        this.applyDateStyle(attr);
    }

    isFirstDayInMonth() {
        return this.options.date.getDate() === 1;
    }

    isLastDayInMonth() {
        const nextDate = new StemDate(this.options.date);
        nextDate.setDate(nextDate.getDate() + 1);
        return nextDate.getDate() === 1;
    }

    render() {

        return [
            <div>
                {this.isFirstDayInMonth() ? <div/> : null}
            </div>,
            <div>
                {this.isLastDayInMonth() ? <div/> : null}
            </div>,
            <div>{parseInt(this.options.date.format("DD"), 10)}</div>
        ];
    }

    onMount() {
        super.onMount();

        this.addNodeListener("mouseenter", () => {
            this.options.onHover(this.options.date);
        });
        this.addNodeListener("mouseleave", () => {
            this.options.onHover(null);
        });
        this.addClickListener(() => {
            this.options.onHover(null);
            this.options.onSelect(this.options.date);
        });
    }
}
