import {StemDate} from "../../stem-core/src/time/Date";
import {TimeUnit} from "../../stem-core/src/time/Duration";
import {Toast} from "../../blinkpay/ui/Toast";

const DATE_WITHOUT_YEAR = "MMM DD";
const DATE_WITH_YEAR = "MMM DD, YYYY";

export function CopyTextToClipboard(text) {
    // TODO: maybe use document.execCommand("copy") if this prompts a pop-up
    navigator.clipboard.writeText(text);
    Toast.show("Copied to clipboard");
}

// TODO do a better job with date formatting
export function formatDate(date, includingHour) {
    date = new StemDate(date);
    const dateFormat = (date.isSame(new StemDate(), TimeUnit.YEAR) ? DATE_WITHOUT_YEAR : DATE_WITH_YEAR) +
        (includingHour ? " HH:mm AA" : ""); // TODO custom hour format
    return date.format(dateFormat);
}

export function formatDateWithHour(date) {
    return formatDate(date, true);
}

export function formatDateRange(startDate, endDate) {
    startDate = new StemDate(startDate);
    endDate = new StemDate(endDate);

    const sameYear = startDate.isSame(endDate, TimeUnit.YEAR);
    const bothCurrentYear = sameYear && startDate.isSame(new StemDate(), TimeUnit.YEAR);

    const endDateFormat = bothCurrentYear ? DATE_WITHOUT_YEAR : DATE_WITH_YEAR;
    const startDateFormat = sameYear ? DATE_WITHOUT_YEAR : DATE_WITH_YEAR;

    if (startDate.isSame(endDate, TimeUnit.DAY)) {
        return endDate.format(endDateFormat);
    }

    return `${startDate.format(startDateFormat)} - ${endDate.format(endDateFormat)}`;
}

// TODO move to Stem
export async function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, Math.max(milliseconds, 0)));
}

// Still does a sleep(0) if date
export async function sleepUntil(date) {
    const diff = (new StemDate(date)) - (new StemDate());
    return sleep(diff);
}
