import {registerStyle, styleRule, UI} from "stem-core/src/ui/UI.js";
import {StyleSheet} from "stem-core/src/ui/Style.js";
import {Card} from "../../ui/Card.jsx";
import {CardItems} from "../../ui/CardItems.jsx";
import {AddCreditModal} from "../AddCreditModal.jsx";
import {Money} from "../../../../stem-core/src/localization/Money.js";
import {BlinkButton} from "../../../../core/ui/Button.jsx";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";
import {WalletStore} from "../../../../client/state/WalletStore.js";

class UserWalletStyle extends StyleSheet {
    @styleRule
    addCreditButton = {
        marginTop: 20,
        fontWeight: "normal",
    };

}

@registerStyle(UserWalletStyle)
@autoredraw(WalletStore)
export class UserWallet extends UI.Element {

    onAddCreditClick() {
         const {user} = this.options;
        // TODO - can have multiple
        const wallet = user.getDefaultWallet();

        AddCreditModal.show({user, wallet});
    }

    render() {
        const {user} = this.options;
        // TODO - can have multiple
        const wallet = user.getDefaultWallet();

        return (
            <Card title="Wallet">
                <CardItems
                    items={[
                        ["Id", wallet.id],
                        ["Currency", wallet.getCurrency().isoCode.toUpperCase()],
                        ["Balance", Money.format(wallet.balance, wallet.getCurrency())],
                    ]}
                />
                <BlinkButton className={this.styleSheet.addCreditButton}
                             onClick={() => this.onAddCreditClick()}
                             label="Add Credit">
                </BlinkButton>
            </Card>
        );
    }
}
