import {UI} from "../../../../stem-core/src/ui/UI.js";
import {BlinkInput} from "../../../../blinkpay/ui/Input.jsx";
import {AdminMerchantUserSelector} from "../AdminMerchantUserSelector.jsx";
import {Jurisdiction} from "../../../../client/state/JurisdictionStore.js";
import {CheckboxInput} from "../../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {DropdownInput} from "../../../../core/ui/input/dropdown/DropdownInput.jsx";


export class CreateLegalEntityForm extends UI.Primitive("form") {
    inputRefs = {};

    validateInputs() {
        let isValid = true;

        for (const [key, fieldInput] of Object.entries(this.inputRefs)) {
            fieldInput.validate();
            isValid = isValid && fieldInput.isValid();
        }

        if (!this.ownerSelector.getSelected()) {
            isValid = false;
        }

        if (!this.jurisdictionDropdown.getValue()) {
            isValid = false;
        }

        return isValid;
    }

    getInputValues() {
        return {
            ownerId: this.ownerSelector.getSelected().id,
            name: this.inputRefs.nameInput.getValue(),
            legalName: this.inputRefs.legalNameInput.getValue(),
            jurisdictionId: this.jurisdictionDropdown.getValue()?.id,
            legalEntityType: this.inputRefs.legalEntityType.getValue(),
            isNonProfit: this.isNonProfit.getValue(),
            taxId: this.inputRefs.taxId.getValue(),
            address: this.inputRefs.address.getValue(),
            cardStatementName: this.inputRefs.cardStatementName.getValue(),
            bankAccountLastDigits: this.inputRefs.bankAccountLastDigits.getValue(),
        };
    }

    render() {
        const jurisdictions = Jurisdiction.all();
        return [
            <AdminMerchantUserSelector ref="ownerSelector"
                                       btnText="Choose owner"
                                       notSelectedLabel="No owner selected."
                                       modalOptions={{
                                  title: "Choose owner",
                                  allowUserCreation: true,
                                  createUserBtnLabel: "Add owner",
                              }} />,
            <BlinkInput ref={{parent: this.inputRefs, name: "nameInput"}}
                        label={"Name"}
                        inputAttributes={{placeholder: "Name"}}
                        validators={[{condition: () => !this.inputRefs.nameInput.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "legalNameInput"}}
                        label={"Legal name"}
                        inputAttributes={{placeholder: "Legal name"}}
                        validators={[{condition: () => !this.inputRefs.legalNameInput.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "legalEntityType"}}
                        label={"Legal entity type"}
                        inputAttributes={{placeholder: "Legal entity type"}}
            />,
            <div>
                <CheckboxInput ref="isNonProfit"/>
                <span>Is non-profit</span>
            </div>,
            <DropdownInput ref={"jurisdictionDropdown"}
                           label={"Jurisdiction"}
                           initialValue={jurisdictions.length && jurisdictions[0]}
                           options={jurisdictions}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "taxId"}}
                        label={"Tax id"}
                        inputAttributes={{placeholder: "Tax id"}}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "address"}}
                        label={"Address"}
                        inputAttributes={{placeholder: "Address"}}
                        validators={[{condition: () => !this.inputRefs.address.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "cardStatementName"}}
                        label={"Card statement name"}
                        inputAttributes={{placeholder: "Card statement name"}}
                        validators={[{condition: () => !this.inputRefs.cardStatementName.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "bankAccountLastDigits"}}
                        label={"Bank account last digits"}
                        inputAttributes={{placeholder: "Bank account last digits"}}
                        validators={[{condition: () => !this.inputRefs.cardStatementName.isEmpty()}]}
            />
        ];
    }
}
