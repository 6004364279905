import {BaseDateCalendarDay} from "../../base/BaseDateCalendarDay";
import {CURRENT_PICK} from "../../DateRangePickerConstants";


export class DateRangeCalendarDay extends BaseDateCalendarDay {
    applyDateStyle(attr) {
        const {hoverDate, startDate, endDate, currentPick} = this.options;
        const {styleSheet} = this;
        let circle = "";
        let left = "";
        let right = "";

        // The circle.
        if (hoverDate && this.isEqualTo(hoverDate)) {
            circle = styleSheet.hoverCircle;
        } else if (this.isEqualTo(startDate) || this.isEqualTo(endDate)) {
            if (!hoverDate) {
                circle = styleSheet.greenCircle;
            } else {
                if (currentPick === CURRENT_PICK.startDate) {
                    if (this.isAfter(hoverDate)) {
                        circle = styleSheet.greenCircle;
                    } else {
                        circle = styleSheet.greyCircle;
                    }
                } else {
                    if (this.isBefore(hoverDate)) {
                        circle = styleSheet.greenCircle;
                    } else {
                        circle = styleSheet.greyCircle;
                    }
                }
            }
        } else {
            circle = styleSheet.emptyCircle;
        }

        // The left side
        if (this.isAfter(startDate) && this.isBeforeOrEqual(endDate)) {
            if (!hoverDate) {
                left = styleSheet.leftGreenSection;
            } else {
                if (currentPick === CURRENT_PICK.startDate) {
                    if (this.isAfter(hoverDate)) {
                        left = styleSheet.leftGreenSection;
                    } else {
                        left = styleSheet.leftGreySection;
                    }
                } else {
                    if (this.isBeforeOrEqual(hoverDate)) {
                        left = styleSheet.leftGreenSection;
                    } else {
                        left = styleSheet.leftGreySection;
                    }
                }
            }
        } else if (this.isBeforeOrEqual(startDate) && hoverDate && this.isAfter(hoverDate)
            && currentPick === CURRENT_PICK.startDate) {
            left = styleSheet.leftLightGreenSection;
        } else if (this.isAfter(endDate) && hoverDate && this.isBeforeOrEqual(hoverDate)
            && currentPick === CURRENT_PICK.endDate) {
            left = styleSheet.leftLightGreenSection;
        }

        // The right side
        if (this.isAfterOrEqual(startDate) && this.isBefore(endDate)) {
            if (!hoverDate) {
                right = styleSheet.rightGreenSection;
            } else {
                if (currentPick === CURRENT_PICK.startDate) {
                    if (this.isBefore(hoverDate)) {
                        right = styleSheet.rightGreySection;
                    } else {
                        right = styleSheet.rightGreenSection;
                    }
                } else {
                    if (this.isAfterOrEqual(hoverDate)) {
                        right = styleSheet.rightGreySection;
                    } else {
                        right = styleSheet.rightGreenSection;
                    }
                }
            }
        } else if (this.isBefore(startDate) && hoverDate && this.isAfterOrEqual(hoverDate)
            && currentPick === CURRENT_PICK.startDate) {
            right = styleSheet.rightLightGreenSection;
        } else if (this.isAfterOrEqual(endDate) && hoverDate && this.isBefore(hoverDate)
            && currentPick === CURRENT_PICK.endDate) {
            right = styleSheet.rightLightGreenSection;
        }

        if (circle === styleSheet.greenCircle
            || (circle !== styleSheet.hoverCircle
                && (left === styleSheet.leftGreenSection || right === styleSheet.rightGreenSection))) {
            attr.addClass(styleSheet.lightFont);
        }
        circle && attr.addClass(circle);
        left && attr.addClass(left);
        right && attr.addClass(right);
    }
}
