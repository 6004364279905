import {UI} from "../../../stem-core/src/ui/UIBase";
import {ModalStyle, Modal} from "../../../blinkpay/ui/Modal";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";

export class DashboardModalStyle extends ModalStyle {
    @styleRuleInherit
    modal = {
        width: "80%",
        maxWidth: 1200,
        fontSize: 16,
    }
}

@registerStyle(DashboardModalStyle)
export class DashboardModal extends Modal {

}