import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {Money} from "../../../../stem-core/src/localization/Money.js";
import {PaymentStore} from "../../../../client/state/PaymentStore.js";
import {EndpointPaginator} from "../../../../client/state/EndpointPaginator.js";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable.jsx";
import {Card} from "../../ui/Card.jsx";


export class UserPaymentList extends UI.Element {
    render() {
        const {user} = this.options;

        const paginator = new EndpointPaginator(PaymentStore, "/admin/payments/get_payments/", {
            userIds: [user.id],
        });

        const columns = [
            ["Id", payment => payment.id],
            ["Merchant", payment => payment.getMerchant()],
            ["Type", payment => payment.getType()],
            ["Amount", payment => Money.format(payment)],
            ["Currency", payment => payment.getCurrency()],
            ["Date", payment => payment.createdAt],
        ]

        return (
            <Card title={"Payments"}>
                <SimpleTable paginator={paginator} columns={columns} />
            </Card>
        )
    }
}
