import {field} from "../../../stem-core/src/state/StoreField.js";
import {MerchantStore, Merchant} from "../MerchantStore";
import {MerchantFeesStore} from "./MerchantFeesStore";
import {MerchantStaffStore} from "./MerchantStaffStore";
import {MerchantRevenueStore} from "./MerchantRevenueStore";
import {apiClient} from "../../connection/BlinkApiClient.js";


class AdminMerchant extends Merchant {
    @field("UserProfile") user;         // The owner
    @field(String) cardStatementName;

    getFees() {
        return MerchantFeesStore.find(item => item.merchantId === this.id);
    }

    getUsersAccess() {
        return MerchantStaffStore.filter(item => item.merchantId === this.id);
    }

    getRevenue() {
        return MerchantRevenueStore.get(this.id);
    }
}

export async function apiAdminFetchMerchant(merchantId) {
    await apiClient.get("admin/merchants/merchant_data/", {merchantId});
    return MerchantStore.get(merchantId);
}

export async function apiUpdateMerchant(data) {
    return apiClient.post("admin/merchants/update_merchant/", data);
}

// Overwrite the base object
MerchantStore.ObjectClass = AdminMerchant;
