import {StaticCodeHighlighter} from "../../stem-core/src/ui/CodeEditor";
import {isString} from "../../stem-core/src/base/Utils";
import {formatJSON, formatXML} from "../misc/Formatting";


export class DashboardStaticText extends StaticCodeHighlighter {
    setOptions(options) {
        options = {
            fontSize: 15,
            minLines: 3,
            maxLines: 25,
            aceMode: options.type || "text",
            ...options,
        }
        if (!isString(options.value)) {
            options.value = JSON.stringify(options.value, null, 3);
            options.aceMode = "json";
        }
        if (options.type === "xml" && !options.rawMode) {
            options.value = formatXML(options.value);
        }
        // If json, all objects are formatted
        if (options.type === "json" && (!options.rawMode || !isString(options.value))) {
            options.value = formatJSON(options.value);
        }
        super.setOptions(options);
    }
}
