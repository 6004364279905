import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../stem-core/src/ui/Style";

import {Table, TableRow} from "../../stem-core/src/ui/table/Table";
import {ChevronLeftIcon, ChevronRightIcon} from "../../core/ui/SVGElements.jsx";
import {Select, NumberInput} from "../../stem-core/src/ui/input/Input";
import {BlinkTableStyle, BlinkTablePaginationStyle} from "../common/theme/TableStyle";
import {BlinkLogoSpinner} from "../../core/ui/LoadingSpinner.jsx";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {styleRule} from "../../stem-core/src/decorators/Style";
import {cleanObject} from "../../stem-core/src/base/Utils";
import {Toast} from "../../blinkpay/ui/Toast";


export class PaginationController extends UI.Element {
    getDefaultOptions(options) {
        const initialPageSize = options.initialPageSize || options?.paginator?.getPageSize() || 10;
        const pageSizeOptions = Array.from(new Set([10, 25, 50, 100, initialPageSize]));
        pageSizeOptions.sort((a, b) => a - b);

        return {
            initialPageSize,
            pageSizeOptions,
        }
    }

    render() {
        const {paginator, pageSizeOptions, initialPageSize} = this.options;

        const currentPage = paginator.lastPageLoaded;
        const totalEntriesCount = paginator.getTotalEntries();
        const [firstIndex, lastIndex] = paginator.getRange();

        const paginationStyleSheet = BlinkTablePaginationStyle.getInstance();

        const rangeText = `${firstIndex} - ${lastIndex} of ${totalEntriesCount}`;

        // TODO the page size should probably be hidden in most views
        return [
            pageSizeOptions?.length > 1 && [<strong>Entries: </strong>, <Select
                className={paginationStyleSheet.pageSizeInput}
                options={pageSizeOptions}
                initialValue={initialPageSize}
                onChange={(event, input) => paginator.setPageSize(input.getValue())}
            />],

            <strong>{rangeText}</strong>,

            <button
                style={{marginLeft: 16}}
                className={paginationStyleSheet.pageControlButton}
                disabled={firstIndex <= 1}
                onClick={() => paginator.fetchPage(currentPage - 1)}>
                <ChevronLeftIcon size={21}/>
            </button>,

            <NumberInput
                ref="pageNumberInput"
                className={paginationStyleSheet.pageNumberInput}
                min={1}
                max={Math.max(paginator.getNumPages(), 1)}
                initialValue={Math.max(paginator.lastPageLoaded, 1)}
                onChange={(...args) => paginator.fetchPage(parseInt(args[args.length - 1].getValue()) || 1)}
            />,

            <button
                className={paginationStyleSheet.pageControlButton}
                disabled={lastIndex >= totalEntriesCount}
                onClick={() => paginator.fetchPage(currentPage + 1)}>
                <ChevronRightIcon size={21}/>
            </button>
        ]
    }

    onMount() {
        const {paginator} = this.options;
        this.attachListener(paginator, "pageLoaded", () => {
            this.pageNumberInput.setValue(paginator.lastPageLoaded);
        });
    }
}

class SimpleTableStyle extends StyleSheet {
    @styleRule
    container = {
        position: "relative",
        padding: 1, // TODO this is a semi-hack, since border is actually a boxShadow
    }

    @styleRule
    loading = {
        height: "100%",
        background: "rgba(255,255,255,0.7)",
        position: "absolute",
    };
}

@registerStyle(SimpleTableStyle)
export class SimpleTable extends UI.Element {
    getPaginationController() {
        const {paginator} = this.options;

        if (!paginator || paginator.getTotalEntries() == 0 || paginator.disablePaginationController) {
            return null;
        }

        return <PaginationController style={{alignSelf: "center"}} paginator={paginator} />;
    }

    getHeaderAction() {
        return null;
    }

    getHeader() {
        const headerAction = this.getHeaderAction();
        const paginationController = this.getPaginationController();

        if (!headerAction && !paginationController) {
            return null;
        }

        const tableStyleSheet = BlinkTableStyle.getInstance();

        return <div className={tableStyleSheet.headerSection}>
                {headerAction}
                <div style={{flex: 1}}></div>
                {paginationController}
            </div>
    }

    render() {
        let {entries, paginator, columns, striped, noHeader, rowClass, tableOptions} = this.options;
        const TableClass = this.options.tableClass || Table;
        if (paginator) {
            entries = entries || paginator.getCurrentPageEntries();
        }

        entries = entries || [];

        if (entries.length === 0) {
            entries = [
                <tr>
                    <td colspan={999} style={{margin: "0 auto", textAlign: "center"}}>
                        No entries to display
                    </td>
                </tr>
            ]
        }

        const tableStyleSheet = BlinkTableStyle.getInstance();
        const passedOnTableOptions = cleanObject({rowClass, noHeader, striped, ...tableOptions});

        return [
            this.getHeader(),
            this.loading ? <BlinkLogoSpinner className={this.styleSheet.loading}/> : null,
            <TableClass className={(striped && tableStyleSheet.tableStripped) + (noHeader && tableStyleSheet.noHeader)}
                   entries={entries}
                   columns={columns}
                   {...passedOnTableOptions}
            />,
        ]
    }

    onMount() {
        // TODO this fails if we change the paginator (via a parent redraw for instance)
        const {paginator, noAutofetch} = this.options;
        if (paginator) {
            this.attachListener(paginator, "pageRequested", () => {
                this.loading = true;
                this.redraw();
            });

            this.attachListener(paginator, "pageLoaded", () => {
                this.loading = false;
                this.redraw();
            });

            this.attachListener(paginator, "pageLoadFailed", (error) => {
                this.loading = false;
                this.redraw();
                Toast.showError(error);
            });

            if (!paginator.haveInitiatedFetch() && !noAutofetch) {
                paginator.fetchFirstPage();
            }
        }
    }
}

export class TableHoverStyle extends StyleSheet {
    @styleRule
    container = {
        ":hover": {
            background: "#eee",
            cursor: "pointer",
        }
    }

    @styleRule
    noHoverChild = {
        ":hover": {
            cursor: "auto",
        }
    }
}

export function MakeLinkingRowClass(clickCallback) {
    @registerStyle(TableHoverStyle)
    class ClickableTableRow extends TableRow {
        onMount() {
            this.addClickListener((event) => clickCallback(this.options.entry, event));
        }
    }
    return ClickableTableRow;
}


export class NoClickPropagate extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(TableHoverStyle.getInstance().noHoverChild);
    }

    onMount() {
        // TODO: what about the other events?
        //  blur change click dblclick error focus focusin focusout hover keydown keypress keyup load mousedown mouseenter mouseleave mousemove mouseout mouseover mouseup resize scroll select submit
        this.attachEventListener(this.node, "click", (event) => {
            event.stopPropagation();
        });
    }
}
