import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {EndpointPaginator} from "../../../client/state/EndpointPaginator.js";
import {DashboardTitle} from "../../../dashboard/common/DashboardTitle.js";
import {SimpleTable} from "../../../dashboard/ui/SimpleTable.jsx";
import {CollapsibleTable} from "../../../stem-core/src/ui/table/CollapsibleTable.jsx";
import {DashboardStaticText} from "../../../dashboard/common/DashboardStaticText.jsx";
import {BlinkInputField} from "../../../dashboard/common/Input.jsx";
import {
    DateRangeInput, DateRangeInterval,
} from "../../../dashboard/ui/input/DateRangeInput.jsx";
import {BlinkLogMessageStore} from "../../../client/state/BlinkLogMessageStore.js";
import {LEFT_COLUMN} from "../../../dashboard/common/theme/TableStyle.js";
import {DashboardLabel} from "../../../dashboard/common/DashboardLabel.jsx";
import {Select} from "../../../stem-core/src/ui/input/Input.jsx";
import {LoggingLevel} from "../../../client/state/misc/BaseLogMessage.js";
import {SearchInput} from "../../../dashboard/ui/input/SearchInput.jsx";


class LogMessageDetails extends UI.Element {
    extraNodeAttributes(attr) {
        attr.setStyle({padding: 12});
    }

    renderStacktrace(stacktrace, label = "Stacktrace") {
        return stacktrace && [
            <div>{label}</div>,
            <DashboardStaticText type="text" value={stacktrace}/>,
        ];
    }

    renderExtra(extra) {
        return extra && [
            <div>Extra:</div>,
            <DashboardStaticText type="json" value={extra}/>,
        ]
    }

    render() {
        const {logMessage} = this.options;
        return [
            <div>
                App: {logMessage.app}
            </div>,
            <div>
                Host: {logMessage.hostname}
            </div>,
            <div>
                File: {logMessage.filename} LineNo {logMessage.lineno}
            </div>,
            <div>
                {logMessage.message}
            </div>,
            logMessage.exceptionInfo && [
                <div>Exception info:</div>,
                <DashboardStaticText type="text" value={logMessage.exceptionInfo} />,
            ],
            this.renderStacktrace(logMessage.extra.errorStacktrace),
            this.renderExtra(logMessage.extra),
        ]
    }
}

export class BlinkLogMessagesPage extends UI.Element {
    paginator = new EndpointPaginator(BlinkLogMessageStore, "/admin/log_messages/list/");

    applyFilters() {
        const filters = {
            minLevel: this.minLevelInput.getValue().value,
            ...this.createdAtDateRangeInput.valueOf(),
            messageText: this.messageInput.getValue() || null,
        };
        this.paginator.updateFilter(filters);
    }

    render() {
        const columns = [
            ["Date", message => message.createdAt, LEFT_COLUMN],
            ["Level", message => {
                const {level} = message;
                return <DashboardLabel level={level.getLabelLevel()}>{level}</DashboardLabel>
            }, LEFT_COLUMN],
            ["Merchant", message => message.merchant],
            ["User", message => message.userId, LEFT_COLUMN],
            ["Code", message => message.code, LEFT_COLUMN],
            ["Message", message => message.message, LEFT_COLUMN], // TODO Ellipsis here
        ];

        const simpleTableOptions = {
            tableClass: CollapsibleTable,
            tableOptions: {
                renderCollapsible: (logMessage) => <LogMessageDetails logMessage={logMessage} />,
            }
        }

        const DATE_RANGE_OPTIONS = [
            DateRangeInterval.LAST_24_HOURS,
            DateRangeInterval.LAST_7_DAYS,
            DateRangeInterval.LAST_30_DAYS,
            DateRangeInterval.LAST_3_MONTHS,
            DateRangeInterval.ALL_TIME,
        ];

        return [
            <DashboardTitle title={"Log Messages"}/>,
            <div>
                <BlinkInputField label="Created at">
                    <DateRangeInput
                        ref="createdAtDateRangeInput"
                        style={{maxWidth: 350, display: "inline-block"}}
                        options={DATE_RANGE_OPTIONS}
                        selectedInterval={DateRangeInterval.LAST_24_HOURS}
                        onChange={() => this.applyFilters()}
                    />
                </BlinkInputField>
                <BlinkInputField label="Min Level">
                    <Select
                        options={[LoggingLevel.ERROR, LoggingLevel.WARNING, LoggingLevel.INFO, LoggingLevel.DEBUG]}
                        initialValue={LoggingLevel.ERROR}
                        onChange={() => this.applyFilters()}
                        ref="minLevelInput"
                    />
                </BlinkInputField>
                <BlinkInputField label="Message text">
                    <SearchInput
                        ref="messageInput"
                        inputAttributes={{placeholder: "Case insensitive search"}}
                        onChange={() => this.applyFilters()}
                    />
                </BlinkInputField>
                {/*<BlinkInputField label="Error Code">*/}
                {/*    */}
                {/*</BlinkInputField>*/}
            </div>,
            <SimpleTable {...simpleTableOptions} noAutofetch paginator={this.paginator} columns={columns}/>,
        ]
    }

    onMount() {
        this.applyFilters();
    }
}