import {UI} from "stem-core/src/ui/UI.js";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal.jsx";
import {Money} from "../../../stem-core/src/localization/Money.js";
import {CardItems} from "../ui/CardItems.jsx";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {NumberInput} from "../../../stem-core/src/ui/input/Input.jsx";


export class AddCreditModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Add Credit",
            description: "Add Blink Credit to user's wallet.",
            confirmLabel: "Add Credit",
            confirmAction: () => this.addCredit(),
        }
    }

    async addCredit() {
        const {wallet} = this.options;
        const currency = wallet.getCurrency();
        const creditToAdd = this.addCreditInput?.getValue() || 0;
        const fee = this.addCreditFee?.getValue() || 0;

        const actualCredit = currency.mainUnitsToAmount(creditToAdd);
        const actualFee = currency.mainUnitsToAmount(fee);

        if (!isNaN(actualCredit) && !isNaN(actualFee)) {
            // TODO rename endpoint to admin/wallet/grant_credit
            await this.makeRequest("/admin/users/credit_wallet", {
                walletId: wallet.id,
                userId: wallet.userId,
                amount: actualCredit,
                fee: actualFee,
            });

        }
    }

    getNewBalance() {
        const {wallet} = this.options;
        const currency = wallet.getCurrency();
        const creditToAdd = this.addCreditInput?.getValue() || 0;

        return wallet.balance + currency.mainUnitsToAmount(creditToAdd);
    }

    render() {
        const {user, wallet} = this.options;
        const currency = wallet.getCurrency();

        if (this.waitingForResponse) {
            return <LoadingSpinner/>;
        }

        const inputOptions = {
            onInputChange: () => {
                this.redraw();
            },
            currency: currency,
            minAmount: currency.mainUnitsToAmount(0),
            maxAmount: currency.mainUnitsToAmount(1000),
        };

        const rowStyle = {display: "flex", flexDirection: "row", marginTop:12, alignItems: "center"};
        const labelStyle = {flex:0.15, fontWeight:"bold"};
        const buttonStyle = {flex:0.15};

        return [
            <CardItems items={[
                               ["Email", user.email],
                               ["Current balance", Money.format(wallet.balance, wallet.getCurrency())],
                               ["New balance", Money.format(this.getNewBalance(), wallet.getCurrency())],
                           ]}
            />,

            <div style={rowStyle}>
                <div style={labelStyle}>Credit:</div>
                <NumberInput ref="addCreditInput" style={buttonStyle} {...inputOptions}/>
            </div>,

            <div style={rowStyle}>
                <div style={labelStyle}>Fee:</div>
                <NumberInput ref="addCreditFee" style={buttonStyle} {...inputOptions}/>
            </div>,
        ];
    }
}
