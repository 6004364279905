export const monthFormat = "MMM YYYY";
export const dayFormat = "DD MM YYYY";

// TODO what the fuck is this?
export const CURRENT_PICK = {
    startDate: 0,
    endDate: 1,
};

// TODO: if these are real constants, make them CONSTANT_CASED. Otherwise they are params, and should be arguments
export const daysInWeek = 7;
export const weekStartingDayIndex = 0;
export const rangeDayCalendarPadding = 8;
export const rangeDayCalendarGradientWidth = 18;
