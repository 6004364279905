import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style.js";

import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {BlinkInput, BlinkInputStyle} from "../../../blinkpay/ui/Input.jsx";
import {SearchIcon} from "../../SVGElements.jsx";


class SearchInputStyle extends BlinkInputStyle {
    @styleRuleInherit
    container = {
        color: "inherit",
    };

    @styleRuleInherit
    inputContainer = {
        border: "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
        borderRadius: this.themeProps.BUTTON_BORDER_RADIUS,
        paddingLeft: "20px",
    };

    @styleRuleInherit
    input = {
        padding: 0,
        margin: 0,
    };

    @styleRule
    searchIcon = {
        height: 16,
        position: "absolute",
        pointerEvents: "none",
        left: 10,
        top: "50%",
        transform: "translateY(-50%)",
    };
}

// TODO @input @Andrei this shouldn't inherit BlinkInput
@registerStyle(SearchInputStyle)
export class SearchInput extends BlinkInput {
    render() {
        const {styleSheet} = this;
        return <SearchIcon size={18} className={styleSheet.searchIcon} color={styleSheet.themeProps.INPUT_PLACEHOLDER_COLOR}/>;
    }
}
