// If you give me any of this Juri-my-diction crap...
import {registerStore, StoreObject} from "../../stem-core/src/state/Store";
import {field} from "../../stem-core/src/state/StoreField.js";


@registerStore("Jurisdiction")
export class Jurisdiction extends StoreObject {
    @field(String) countryCode;
    @field(String) name;
    @field("Currency") defaultCurrency;

    toString() {
        return this.name;
    }
}
