import {registerStyle, styleRule, UI} from "stem-core/src/ui/UI.js";
import {Modal, ModalStyle} from "../../../../blinkpay/ui/Modal.jsx";
import {EditableCard} from "../../ui/EdiableCard.jsx";
import {apiUpdateLegalEntity, LegalEntityStore} from "../../../../client/state/LegalEntityStore.js";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";

class LegalEntityModalStyle extends ModalStyle {
    @styleRule
    editableCard = {
        ">:first-child>:first-child": {
            boxShadow: "none",
            backgroundColor: "inherit",
        }
    }
}

@registerStyle(LegalEntityModalStyle)
@autoredraw(LegalEntityStore)
export class LegalEntityModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            legalEntity: null,
        };
    }

    render() {
        const {legalEntity} = this.options;

        return <EditableCard className={this.styleSheet.editableCard}
                             values={legalEntity}
                             editableFields={["name", "legalName", "legalEntityType", "isNonProfit", "taxId", "ownerId",
                                 "streetAddress", "stateAddress", "cardStatementName", "bankAccountLastDigits"]}
                             onUpdate={(data) => apiUpdateLegalEntity({legalEntityId: legalEntity.id, ...data})}
        />
    }
}
