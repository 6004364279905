import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {SubscriptionStore} from "../../../../client/state/SubscriptionStore.js";
import {ArrayPaginator} from "../../../../client/state/EndpointPaginator.js";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable.jsx";
import {Card} from "../../ui/Card.jsx";

export class UserSubscriptionList extends UI.Element {
    render() {
        const {user} = this.options;
        const paginator = new ArrayPaginator(SubscriptionStore.filterBy({
            userId: user.id,
        }));

        const columns = [
            ["Id", sub => sub.id],
            ["Offer", sub => sub.offerId],
            ["Created at", sub =>sub.createdAt],
            ["Canceled at", sub => sub.canceledAt],
            ["Status", sub => sub.status],
        ];

        return (
            <Card title="Subscriptions">
                <SimpleTable columns={columns} paginator={paginator} />
            </Card>
        );
    }
}
