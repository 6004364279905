import {UI} from "stem-core/src/ui/UI.js";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal.jsx";
import {CreateMerchantForm} from "./CreateMerchantForm.jsx";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";


export class CreateMerchantModal extends ConfirmationModal {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            title: "Create Merchant",
            confirmLabel: "Create Merchant",
            confirmAction: () => this.createMerchant(),
        }
    }

    async createMerchant() {
        const isFormValid = this.createMerchantForm && this.createMerchantForm.validateInputs();
        if (!isFormValid) {
            return;
        }

        await this.makeRequest("/admin/merchants/create_merchant", this.createMerchantForm.getInputValues());
    }

    render() {
        return [
            <CreateMerchantForm ref="createMerchantForm"/>,
            this.waitingForResponse && <LoadingSpinner/>,
        ];
    }
}
