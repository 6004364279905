import {Route} from "../stem-core/src/ui/Router";
import {unwrapArray} from "../stem-core/src/base/Utils";
import {merchantService} from "./misc/MerchantService";

// For all pages without subroutes, just take in all their arguments
class PageRoute extends Route {
    constructor(url, uiClass, urlTitle) {
        super(url, uiClass, [], urlTitle);
    }

    matches(urlParts) {
        if (urlParts[0] != this.expr[0]) {
            return null;
        }
        return {
            args: urlParts.splice(1),
            urlParts: urlParts.splice(1),
        }
    }

    getPage(urlParts, router, ...argsArray) {
        const options = {
            args: unwrapArray(argsArray),
            baseURL: this.expr[0],
            merchant: merchantService.getMerchant(),
        };
        if (this.uiElement) {
            this.uiElement.updateOptions(options);
        } else {
            this.uiElement = new this.pageGenerator(options);
        }
        return this.uiElement;
    }
}

export class Page {
    static titleSuffix = " - Blink Dashboard";

    constructor(url, name, uiClass, options) {
        this.url = url;
        this.name = name;
        this.uiClass = uiClass;
        Object.assign(this, options);
        this.isMainSection = this.isMainSection ?? (this.icon != null);
    }

    toRoute(subRoutes=[]) {
        const urlTitle = this.name + this.constructor.titleSuffix;
        if (subRoutes) {
            return new Route(this.url, this.uiClass, subRoutes, urlTitle);
        }
        // We want to be terminal regardless of other arguments
        return new PageRoute(this.url, this.uiClass, urlTitle);
    }
}
